const es = {
  translations: {
    page: {
      schedules: {
        home: {
          schedules: "Horario",
          nextSchedules: "Siguientes citas",
          noFound: "No hay horario para los próximos días",
          actions: {
            newSchedule: "Nuevo programación",
            mySchedules: "Mis citas",
            familyGroup: "Grupo Familiar",
            insurance: "Mis convenios",
          },
        },
        insurance: {
          title: "Mis acuerdos",
          subtitle: "Administre sus acuerdos y los del grupo familiar",
          add: "Para agregar",
          individual: "Individual",
          insuranceName: "Acuerdo",
          cardNumber: "numero de tarjeta",
          cardValidity: "Validez",
          delete: {
            title: "Eliminar un acuerdo?",
            description: "¡Esta acción es permanente!",
            confirmationText: "Si, eliminar",
            cancellationText: "Cancelar",
          },
          addInsurance: {
            edit: "Editar acuerdo",
            add: "Agregar acuerdo",
            buttonSave: "Ahorrar",
            buttonEdit: "Para editar",
            number: {
              label: "Número de tarjeta (opcional)",
              error: "El número es obligatorio",
            },
            validity: {
              label: "Validez de tarjeta (opcional)",
              error: "La validez es obligatoria",
            },
            individual: {
              label: "Paciente",
              error: "El paciente es obligatorio",
            },
            insurance: {
              label: "Seguro de salud",
              error: "El acuerdo es obligatorio",
            },
          },
        },
        scheduleDetails: {
          title: "Detalles de programación",
          professional: "Profesional",
          type: "Tipo de programación",
          reschedule: "Reagencia",
          insurance: "Seguro de salud",
          patient: "paciente",
          confirmModal: {
            title: "confirmarAgenda",
            description:
              "Debe confirmar la programación con {{min}} minutos de anticipación.",
          },
          status: "Estado",
          confirm: {
            button: "confirmar",
            confirmed: "Programación confirmada",
          },
          cancel: {
            button: "cancelar",
            success: "Programación exitosa con éxito",
            title: "Horario de cancelación?",
            title2: "Restablecer programación?",
            description2:
              "Para reaccionar primero, debe cancelar la programación, complete el motivo a continuación y luego puede seleccionar una nueva cita",
            description:
              "¿Confirma la cancelación de su día de programación {{date}} con {{professional}}?",
            justification: {
              label: "Justificación",
              placeholder: "Ingrese el motivo para cancelar la agenda",
              error: "La justificación es obligatoria",
            },
            reason: {
              label: "Razón de la cancelación",
              placeholder: "Seleccione el motivo para cancelar la agenda",
              error: "O motivo é obrigatório",
            },
            cancel: "No",
            confirm: "Si, cancele",
          },
        },
        addFamilyGroup: {
          birthDate: {
            label: "Fecha de nacimiento",
            error: "La fecha de nacimiento es obligatoria",
          },
          kinshipDegree: {
            label: "parentesco",
            error: "El parentesco es obligatorio",
          },
          document: {
            label: "CPF",
            error: "El CPF es obligatorio",
          },
          name: {
            label: "Nombre",
            error: "O nome é obrigatório",
          },
          nationality: {
            label: "Nacionalidad",
            error: "La nacionalidad es obligatoria",
          },
          gender: {
            label: "Género",
            error: "El género es obligatorio",
          },
          title: "Grupo Familiar",
          subtitle: "Administrar miembros de su grupo familiar",
          add: "Para agregar",
          individual: "Individual",
          delete: {
            title: "¿Eliminar la familia?",
            description: "¡Esta acción es permanente!",
            confirmationText: "Si, eliminar",
            cancellationText: "Cancelar",
          },
          titleAdd: "Agregar familiar",
          titleEdit: "Editar Familiar",
          edit: "Para editar",
          save: "Ahorrar",
        },
        mySchedules: {
          title: "Mis agendas",
          subtitle:
            "Ver sus agendas futuras y la historia de los horarios pasados",
          next: "Siguiente agendas",
          historic: "Histórico",
          status: "Estado",
          add: "Para agregar",
          type: {
            3: "Consulta",
            2: "Examen",
          },
          date: "Datos",
          pacientName: "Paciente",
          descriptionSite: "Descripción",
          tasySchedulingType: "Tipo",
          checkin: "Check in",
        },
        newSchedule: {
          success: "Programación registrada exitosa",
          sched: "Para programar",
          next: "próximo",
          back: "Volver",
          notAvaiableModal: {
            button: "De acuerdo",
            description:
              "El tiempo elegido ya no está disponible, seleccione otra vez.",
          },
          finish: "finalizar",
          no_type:
            "No tienes ningún tipo de cita disponible, por favor contacta con los administradores del sistema",
          steps: {
            patient: {
              title: "Seleccione al paciente",
              error: "El paciente es obligatorio",
            },
            insurance: {
              title: "Seleccione el Acuerdo",
              error: "El acuerdo es obligatorio",
            },
            search: {
              title: "Buscar una agenda",
              specialityError: "La especialidad es obligatoria",
              examError: "El examen es obligatorio",
            },
            schedule: {
              title: "Seleccione una fecha y hora",
              error: "El tiempo es obligatorio",
            },
            confirm: "confirmarDatos",
          },
          insurance: "Seguro de salud",
          addInsurance: "Agregar acuerdo",
          addFamilyGroup: "Agregar familiar",
          search: {
            from: "Desde",
            examGroup: "Examen",
            professional: "Profesional (opcional)",
            speciality: "Especialidad",
            type: "Tipo de programación",
            period: "período (opcional)",
          },
          schedule: {
            freeDays: "Días con fechas disponibles",
          },
          confirm: {
            descriptionSite: "Profesional",
            patient: "paciente",
            insurance: "Seguro de salud",
            schedule: "Planificación",
            antecipate:
              "Me gustaría que me avisen si hay horarios libres antes de esta fecha. En este caso, si alguien cancela una agenda antes de este día, le avisaremos de esta oportuna oportunidad.",
            whatsapp_number: "Numero de WhatsApp",
            edit: "Editar",
            cancel: "Cancelar",
            confirm: "Confirmar",
            errors: {
              whatsapp_number: "El numero de WhatsApp es obligatorio",
            },
          },
        },
      },
      login: {
        welcome: "Bienvenido de nuevo",
        username: "Nombre de usuario",
        cpf: "CPF",
        password: "Contraseña",
        rememberme: "Acuérdate de mí",
        forget_password: "¿Has olvidado tu contraseña?",
        dont_account: "¿No tienes una cuenta?",
        login: "Acceso",
        confirm_email: "Confirmar email",
        errors: {
          login: "El login es obligatorio",
          password: "La contraseña debe contener 6 caracteres como mínimo",
        },
      },
      loginTemporary: {
        welcome: "Bienvenido(a)",
        login: "Login",
        password: "Contraseña",
        dont_account: "¿No tienes una cuenta?",
        access_button: "Acceso",
        access_exam: "Acceso a exámenes",
        errors: {
          login: "El login es obligatorio",
          password: "La contraseña es obligatoria",
        },
      },
      not_found: {
        page_not_found: "¡Uy! ",
        server_not_responding: "Servidor no responde",
        try_again: "Intentar otra vez",
        back_to_begin: "De vuelta a casa",
        back_to_login: "Atrás para iniciar sesión",
        something_went_wrong: "Algo salió mal",
      },
      documents: {
        title: "Términos y políticas",
        subtitle: "Estos son los documentos que aceptó",
        no_docs:
          "Todavía no ha aceptado ningún documento. Después de aceptar cualquier documento, aparecerá aquí",
      },
      home: {
        ticket: "Boleto duplicado",
        coParticipation: "Extracto de coparticipación",
        paymentHistory: "Historial de pagos",
        authorization: "Autorizaciones",
        dependent: "Dependiente",
        irAndReimbursement: "IR",
        refunds: "Solicitudes de reembolso",
        slogan: "Haz tu vida diaria más fácil",
        faq: "FAQ",
        schedule: "Agendas",
        health_program: "Programas de salud",
        exams: "Resultados de examen",
      },
      cards: {
        card: "Tarjeta virtual",
        plan: "Plan",
        no_cards: "Sin tarjeta registrada",
        view_card: "ver tarjeta",
        show_shared_card: "Agregar tarjeta compartida",
        share_card: "Compartir",
        name: "Nombre",
        number: "Número",
        birth_date: "Fecha de nacimiento",
        plan_name: "Producto",
        accommodation: "Alojamiento",
        coverage: "Cobertura",
        accession: "Fecha de adhesión",
        contract_name: "Contratista",
        card_validity: "Validez de la tarjeta",
        card_lack_description: "Carece",
        card_lack_unlock_date: "Enviado desde",
        card_lack_usage: "Tarjeta de uso personal e intransferible.",
        card_lack_presentation:
          "Debe presentarse junto con un documento de identificación.",
      },
      register: {
        register: "Registro",
        document: "Documento",
        has_register: "¿Ya registrado? ",
        birth_date: "Fecha de nacimiento",
        email: "Correo electrónico",
        confirm_email: "Confirmar correo electrónico",
        password: "Contraseña",
        confirm_password: "Confirmar Contraseña",
        notification: "Registrado correctamente",
        errors: {
          document: "El documento es obligatorio.",
          password: "La contraseña es obligatoria",
          email: "El correo electrónico es obligatorio",
          confirm_email: "Los correos electrónicos deben coincidir",
          confirm_password: "Las contraseñas deben coincidir",
          birth_date: "La fecha de nacimiento es obligatoria.",
        },
        emailSendMessage:
          "Le enviamos un correo electrónico para confirmar la creación de la cuenta. Compruebe la pestaña de correo no deseado :)",
        backToLogin: "Volver al inicio de sesión",
        individuals: {
          contacts: "Contactos",
          contact_type: "Tipo de contacto",
          add_contact: "Añadir contacto",
          addresses: "Direcciones",
          add_address: "Añaadir dirección",
        },
      },
      irpf: {
        irpf: "Extracto de IRPF",
        year: "Año",
        download_irpf: "Descargar IRPF",
        downloading: "Descargando",
        select_year: "Seleccionar año",
        no_data:
          "No se ha enumerado la información de IRPF porque no es un pagador de los contratos de seguro de salud. Para obtener este informe, busque su contrato con el operador del plan de salud.",
        plan: "Plan",
        no_file: {
          title: "archivo no disponible",
          description: "no tienes el {{year}} IRPF disponible para descargar.",
        },
      },
      extractCoParticipation: {
        extract_coparticipation: "Declaración de coparticipación",
        select_date: "Seleccione fecha",
        beneficiary: "Beneficiario",
        procedure: "Procedimiento",
        procedure_date: "Fecha",
        quantity: "Cantidad",
        provider: "Proveedor",
        value: "Precio",
        total: "Total",
        total_plan: "total del plan",
      },
      historyPayment: {
        history_payment: "Boleto duplicado",
        title_number: "Número de título",
        plan_name: "Nombre del plan",
        plan: "Plan",
        reference_date: "Fecha de referencia",
        due_date: "Fecha de vencimiento",
        payment_date: "Fecha de pago",
        amount_fine: "Cantidad pagada",
        amount_interest: "Cantidad de interés",
        amount_penality: "Buen valor",
        amount_monthly: "Monto mensual",
        download_ticket: "Descargar billete",
        donwloads: "Descargar documentos",
        detail: "Descargar detallando",
        select_year: "Seleccionar año",
        no_data:
          "No se ha enumerado la información de pago porque no es un pagador de los contratos de seguro de salud. Para obtener este informe, busque su contrato con el operador del plan de salud.",
      },
      changePassword: {
        change_password: "Cambiar la contraseña",
        actual_password: "Contraseña real",
        new_password: "Nueva contraseña",
        save: "Ahorrar",
        confirm_new_password: "Confirmar nueva contraseña",
        success_message: "Contraseña cambiada con éxito",
        errors: {
          actual_password: "La contraseña actual es obligatoria",
          new_password: "La nueva contraseña es obligatoria",
          confirm_new_password: "La contraseña de confirmación es obligatoria",
          passwords_match: "Las contraseñas no coinciden",
        },
      },
      confirm_email: {
        confirm_email: "Confirmar correo electrónico",
        confirming: "Confirmando",
        resent: "Correo electrónico reenviado con éxito",
        success: {
          title: "Correo electrónico confirmado",
          description:
            "Su correo electrónico ha sido confirmado con éxito, vuelva al inicio de sesión para acceder a su cuenta",
        },
        subtitle:
          'Ingrese el código recibido por correo electrónico a continuación y luego haga clic en "Confirmar email".',
        username: "Nombre de usuario",
        document: "CPF",
        code: "Código",
        error: {
          title: "Hubo un error",
          description:
            "Ocurrió un error al confirmar el correo electrónico, intente nuevamente",
          action: "Redirigir correo",
          username: "El {{username}} es obligatorio",
          code: "El Código es obligatorio",
        },
      },
      medicGuide: {
        title: "Resultados",
        show_result: "Tu búsqueda fue:",
        you: "Tú",
      },
      authorizations: {
        authorizations: "Permisos",
        plan_name: "Plan",
        select_period: "Seleccione un período",
        see_more: "Ver más",
        provider: "Proveedor",
        doctor: "Doctor",
        beneficiary: "Beneficiario",
      },
      authorization: {
        authorization: "Permiso",
        authorization_code: "Número de solicitud",
        issue_date: "Fecha de emisión",
        password_code: "Solicitud de contraseña",
        password_expiration: "Validez de la contraseña",
        situation: "Situación",
        provider: "Proveedor",
        doctor: "Doctor",
        procedures: "Trámites",
        history: "Histórico",
        service_protocol: "Servicio de protocolo",
        doc_type: "Tipo",
        beneficiary: "Beneficiário",
      },
      noAccess: {
        message:
          "¡Ups! ¡Parece que no tienes permiso para acceder a esta página!",
        button: "volver arriba",
      },
      whatsapp: {
        redirect: "Redireccionando...",
      },
      refundsPage: {
        refunds: "Solicitudes de reembolso",
        new_refund: "Nueva solicitud",
        select_date: "Selecciona la fecha",
        beneficiary: "Recipiente",
        protocol_date: "Fecha del protocolo",
        situation: "Situación",
        due_date: "Fecha de vencimiento",
        amount: "Valor",
        service_reason: "Motivo de reembolso",
        refund: {
          refund: "Nuevo reembolso",
          select_beneficiary: "Seleccione un beneficiario",
          basic_data: "Datos básicos",
          others_data: "Información bancaria y contactos.",
          name: "Nombre",
          type_guide: "Tipo de servicio",
          service_date: "Fecha de servicio",
          service_value: "Valor del servicio",
          service_location: "Ubicación del servicio",
          service_reason: "Motivo de reembolso",
          holder_name: "Nombre del titular",
          holder_document: "CPF del titular",
          holder_birth_date: "Fecha de nacimiento",
          bank_data: "Datos bancarios",
          bank_data_no_found:
            "No encontré los datos bancarios en la lista anterior y quiero ingresarlos manualmente",
          bank: "Banco",
          bank_branch: "Agencia",
          bank_account: "Cuenta",
          bank_account_digit: "Dígito",
          files: "Fotos del recibo de reembolso.",
          file: "Archivo",
          add_file: "Agregar archivo",
          contacts: "Contactos",
          phone_contact: "Teléfono",
          mail_contact: "Email",
          save: "Pedido",
          refund_requested: "Reembolso solicitado exitosamente",
          errors: {
            // Step 1
            type_guide: "El tipo de servicio es obligatorio",
            service_date: "La fecha de servicio es obligatoria",
            service_value: "El valor del servicio es obligatorio",
            service_location: "La ubicación del servicio es obligatoria",
            service_reason: "El motivo de reembolso es obligatorio",
            files: "Al menos un archivo es obligatorio",
            file: "El archivo es obligatorio",
            // Step 2
            bank_data: "Los datos bancarios son obligatorios",
            holder_name: "El nombre del titular es obligatorio",
            holder_document: "El CPF del titular es obligatorio",
            bank: "El banco es obligatorio",
            holder_birth_date: "La fecha de nacimiento es obligatoria",
            bank_branch: "La agencia es obligatoria",
            bank_account: "La cuenta es obligatoria",
            bank_account_digit: "El dígito es obligatorio",
            phone_contact: "El teléfono es obligatorio",
            mail_contact: "El email es obligatorio",
            mail_contact_val: "Introduzca un email válido",
          },
        },
      },
      scheduleTeleconsultation: {
        schedule_teleconsultation: "Agenda una teleconsulta",
        choose_service: "¿Qué servicio quieres?",
        virtual_emergency_care: "Atención de Emergencia Virtual",
        service: "Videoconsulta con un médico, por orden de activación.",
        service_call:
          "Consulta telefónica con un médico, por orden de activación.",
      },
      emergencyCareScheduling: {
        appointment_scheduling: "Programación de citas",
        choose_patient: "¿Para quién desea programar una cita?",
        dependents: "Dependiente(s)",
      },
      virtualEmergencyCare: {
        virtual_emergency_care: "Atención de emergencia virtual",
        message:
          "Hola, este es nuestro servicio de atención de emergencia virtual. Aquí tendrás acceso a un médico estés donde estés y cuando lo necesites. Ingresa tus síntomas, haz clic en “Solicitar Servicio” y espera hasta ser atendido.",
      },
      faq: {
        faq: "FAQ",
        search: "Buscar",
        search_placeholder: "Buscar una pregunta o respuesta",
        no_data: "Ningún resultado encontrado",
        no_questions_registered: "No hay preguntas registradas",
      },
      allNews: {
        title: "Noticias",
        no_news: "No hay noticias para mostrar",
        search_label: "Buscar",
        search_placeholder: "Buscar por noticias y tags",
      },
      news: {
        reading_time: "Tiempo estimado de lectura: {{readingTime}} minutos",
        publication_date: "Fecha de publicación:",
        complementaryMaterial: "Material complementario",
        no_video: "El video no fue encontrado",
      },
      notifications: {
        title: "Notificaciones",
        mark_all_as_read: "Marcar todo como leido",
      },
      notification: {
        title: "Notificación",
        created: "Fecha de publicación",
        read: "Fecha de lectura",
      },
      health_programs: {
        title: "Programas de salud",
        search: "Buscar un programa de salud",
        not_participate: "Todavía no participas en ningún programa de salud",
        no_found: "No se encontró ningún programa",
        myPrograms: "Mis programas",
        otherPrograms: "Otros programas",
        seeMore: "Ver más",
        exit: "Salir",
        requestToEnter: {
          title: "No fue posible ver",
          description:
            "No eres parte de este programa.¿Quieres solicitar ingresar?",
          button1: "cancelar",
          button2: "Pedido",
        },
        refused: {
          title: "Solicitud rechazada",
          button1: "Cancelar",
          button2: "Reiniciar",
        },
        hasRequested: {
          title: "Solicitud en progreso",
          description: "Espere la respuesta de los responsables",
          button1: "Ok",
        },
        exitProgram: {
          title: "Salir del programa?",
          description:
            "¿Estás seguro de que quieres salir del programa?Deberá solicitar permiso para ingresar nuevamente.",
          button1: "Fuera del programa",
          button2: "Cancelar",
        },
        dateInfo: "{{date}} - Tiempo de lectura {{readingTime}} min",
      },
      exams: {
        exams: "Resultados de examen",
        procedure: "Procedimiento",
        type: "Tipo de Procedimiento",
        doctor: "Doctor",
        unit: "Unidad",
        date: "Fecha",
        image: "Imagens",
        report: "Informe",
        search: "Buscar",
        date_range_info: "El límite máximo de consulta es de 1 año",
        patient: "Paciente",
      },
      myRegistration: {
        my_registration: "Mi Registro",
        my_registration_menu: "Mi registro",
        name: "Nombre",
        document: "CPF",
        birth_date: "Fecha de Nacimiento",
        contacts: "Contactos",
        contact_type: "Tipo de contacto",
        add_contact: "Añadir contacto",
        save: "Ahorrar",
        cancel: "Cancelar",
        success: "Registro actualizado exitosamente",
      },
    },
    components: {
      menu: {
        home: "Hogar",
        schedule: "Agendas",
        whats: "Whatsapp",
        faq: "FAQ",
        virtualEmergencyCare: "Telemedicina",
        cards: "Tarjeta virtual",
        ticket: "Boleto duplicado",
        co_participation: "Extracto de coparticipación",
        irpf: "IRPF",
        authorizations: "Permisos",
        plan: "Plan de seguro",
        config: "Configuraciones",
        change_password: "Cambiar la contraseña",
        change_language: "Cambiar idioma",
        logout: "Cerrar sesión",
        settings: "Ajustes",
        whatsapp: {
          title: "En desarrollo",
          description: "Algo increíble se está gestando. ",
        },
      },
      backButton: "Atrás",
      private: {
        permission: "¡Uy! ",
      },
      themeSwitch: {
        change_theme: "Cambiar de tema",
      },
      languageSwitch: {
        change_language: "Cambiar idioma",
      },
      recents: {
        status: {
          analyze: "Bajo revisión",
          expiresToday: "Vence hoy",
        },
      },
      navigationCardsPage: {
        title: "Tarjeta virtual",
        action: "Ver sus tarjetas virtuales o tarjetas compartidas con usted",
      },
      notifications: "Notificaciones",
      no_data: "No hay datos para mostrar",
      establishments: {
        select: "Seleccione un establecimiento",
        change: "Cambiar establecimiento",
      },
      beneficiaryCard: {
        share: "Con quién estoy compartiendo:",
      },
      medicCardShare: {
        share_with:
          "Introduce el documento con el que quieres compartir la tarjeta virtual {{cardCode}} de {{individualName}}",
        responsibility_message:
          "Es responsabilidad del TITULAR del plan de salud compartir y difundir la información personal contenida en esta tarjeta virtual. ",
        read: "He leído y estoy de acuerdo con los términos anteriores",
        document: "Documento",
        name: "nombre de la persona",
        cancel: "Cancelar",
        share: "Compartir",
        errors: {
          document: "El documento es obligatorio.",
          name: "El nombre es obligatorio",
          terms: "Debe aceptar los términos para compartir",
        },
        success: "Tarjeta virtual compartida con éxito",
        deleted: "Dejaste de compartir con {{name}}",
      },
      medicCardShared: {
        message:
          "Ingrese su DOCUMENTO y el código para mostrar la tarjeta compartida con usted.",
        see_card: "Agregar tarjeta",
        document: "Documento",
        code: "codigo",
        errors: {
          document: "El documento es obligatorio.",
          code: "El código es obligatorio",
          card_added: "Tarjeta virtual ya agregada",
        },
      },
      changeLanguageForm: {
        title: "Cambiar idioma",
        language: "Idioma",
        button: "Cambiar",
      },
      autocomplete: {
        noOptions: "Sin opciones",
        hint: "Escriba al menos 3 caracteres para buscar",
      },
      medicGuide: {
        medic_guide: "Guía médica",
        subtitle: "Busca por médico, especialidad, clínica...",
        search: "¿Qué estás buscando?",
      },
      downloadTicket: {
        ticket_value: "Valor de la factura",
        ticket_payment_date: "Fecha de vencimiento",
        payment_code: "Código de pago",
        copy_code: "Copiar código",
        download_ticket: "Descargar billete",
        downloading: "Descargando",
        code_copied: "código copiado",
      },
      documentAcceptance: {
        document_acceptance: "Documentos",
        document_message:
          "Al aceptar, acepta y acepta los documentos que se enumeran a continuación.",
        document_accept:
          "Debe aceptar los documentos para continuar usando el sitio",
        document_accepted: "Documentos aceptados con éxito",
        need_accept: "Debe aceptar los términos para continuar",
        accept: "Aceptar",
        image_alt: "Papel",
        language: "Idioma",
        content: "Contenido",
      },
      document: {
        acceptIn: "Aceptado en {{date}}",
      },
      autoCompleteVoice: {
        permission: "Necesitas permitir el micrófono",
        support: "El navegador no es compatible con el reconocimiento de voz",
        no_options: "Sin opciones",
        specialty: "Especialidad",
        practice_area: "Área de actuación",
        city: "Ciudad",
        health_plan: "Departamento",
        professional: "Nombre",
        provider_type: "Tipo de proveedor",
      },
      avatarDropdown: {
        tooltip_user: "Configuraciones de la cuenta",
        tooltip_visit: "Acceso",
      },
      forgetPasswordForm: {
        title: "Esqueceu a senha?",
        subtitle:
          "Ingrese su CPF a continuación y le enviaremos un código a su correo electrónico para restablecer su contraseña.",
        button: "Enviar",
        login: "CPF",
        login_required: "¡Correo electronico es requerido!",
        notification_email_sent: "Correo electrónico enviado con éxito!",
        back_login: "Atrás para iniciar sesión",
        notification_email_confirmed:
          "Correo electrónico confirmado exitosamente, ingrese nuevamente sus datos de acceso.",
      },
      resetPasswordForm: {
        title: "Nueva contraseña",
        subtitle:
          "Ingresa el código enviado a tu correo electrónico. Si no lo encuentras, revisa tu área de spam.",
        password: "Contraseña",
        confirm_password: "Confirmar Contraseña",
        confirmation_code: "Código de confirmación",
        new_password_required: "Se requiere contraseña.",
        confirm_new_password_required: "Se requiere confirmar la contraseña.",
        passwords_match: "Las contraseñas no coinciden.",
        confirmation_code_required: "El código de confirmación es obligatorio",
        notification_password_success: "¡Contraseña cambiada con éxito!",
        counter:
          "Reenviar correo electrónico disponible en {{counter}} segundo(s)",
      },
      resendEmail: {
        title: "Parece que aún no ha confirmado su correo electrónico.",
        description:
          "Haga clic en el botón de abajo para reenviar el correo electrónico de confirmación.",
        button: "Reenviar email",
        success: "Correo electrónico enviado con éxito!",
      },
      downloadPageButton: {
        error: "Descarga fracasó",
      },
      procedures: {
        code: "Código",
        authorized_quantity: "Cant autorizada ",
        requested_quantity: "Cant solicitada",
        pending_execution_quantity: "Cant pendiente de ejecución",
        executed_quantity: "Cant ejecutada",
      },
      stepperActions: {
        back: "Volver",
        next: "Avance",
      },
      stepperHeader: {
        errors: {
          fields: "Campo obligatorio en blanco o con error",
        },
      },
      inputFileStatus: {
        tooltip_view_file: "Ver archivo",
        tooltip_try_again: "Intentar nuevamente",
      },
      inputFileValidations: {
        invalid_type: "Tipo de archivo no soportado",
        invalid_size:
          "Tamaño de archivo no admitido. (Máx. {{maxFileSize}} MB)",
      },
      refundsModal: {
        title: "Seleccione un beneficiario",
        select: "Seleccionar",
        beneficiary: "Beneficiario",
        errors: {
          beneficiary: "El beneficiario es obligatorio",
        },
      },
      schedulingBeneficiary: {
        birth_date: "Fecha de nacimiento",
        gender: "Sexo",
        mail: "Email",
        phone: "Teléfono",
      },
      emergencyCareSymptoms: {
        symptoms: "¿Cuáles son tus síntomas?",
        request_service: "Solicitar servicio",
        requesting: "Solicitando",
        beneficiary_symptoms: "Fiebre, dolor de cabeza...",
        cancel: "Cancelar",
      },
      medicGuideFilter: {
        detailed_search: "Búsqueda detallada",
        legends: "Subtítulos",
        plan: "Departamento",
        province: "Estado",
        city: "Ciudad",
        neighborhood: "Vecindario",
        specialty: "Especialidad",
        character_service: "Carácter del servicio",
        occupation_area: "Área de actuación",
        provider_type: "Tipo de proveedor",
        doctor: "Doctor",
        search: "Buscar",
        name: "Nombre",
        emergency: "Atención de urgencia y emergencia únicamente",
        clean: "Limpiar filtros",
        no_medic_found: "No se encontró ningún médico!",
        specialties: "Especialidades",
        practice_area: "Área de actuación",
        phone: "Teléfono",
        cellphone: "Teléfono móvil",
        whatsapp: "Whatsapp",
        website: "Site",
        mail: "Email",
        filters_added: "Filtros agregados exitosamente.",
        filters_removed: "Filtros eliminados exitosamente.",
        view_more: "Ver más",
        view_less: "Ver menos",
      },
      medicGuideActions: {
        list: "Lista",
        map: "Mapa",
      },
      queueModal: {
        go_queue: "Ir a la cola",
        copy_link: "Copiar link",
        link_copied: "Enlace copiado exitosamente",
        image_alt:
          "Teléfono celular con estetoscopio, símbolo del servicio de emergencia virtual",
        title: "¡No pasará mucho tiempo antes de que te atiendan!",
        subtitle:
          'Ya estás en la cola, simplemente haz clic en el botón "Ir a la cola" y espera a que te atiendan.',
        close: "Cerrar",
      },
      lastCalls: {
        last_calls: "Últimas llamadas",
        return: "Reanudar",
        return_tooltip: "Regresar al servicio",
        use_symptoms: "Utilice los síntomas",
        symptoms: "Síntomas",
      },
      displayPlan: {
        plan: "Plan",
      },
      refundSelectBeneficiary: {
        dependents: "Dependiente(s)",
      },
      autoCompleteBankData: {
        short_name: "Banco",
        bank_branch: "Agencia",
        bank_account: "Cuenta",
        holder_account: "Poseedor",
      },
      legends: {
        national: "Estándar de calidad nacional",
        international: "Estándar de calidad internacional",
        events_comunication: "Notificación de eventos adversos",
        monitored_quality: "Calidad monitoreada",
        residence_professional: "Profesional con residencia",
        specialty_professional: "Profesional especializado",
        specialist_title: "Título de especialista",
      },
      avaliationsButtons: {
        title: "¿Te resultó útil este artículo",
      },
      avaliationInput: {
        placeholder:
          "Agradecemos sus comentarios, agregue un comentario aquí para ayudar a mejorar el artículo.",
        button: "Enviar comentario",
        sending: "Enviando",
        back: "Volver",
      },
      rated: {
        message: "Agradecemos sus comentarios!",
      },
      registeredModal: {
        title: "¡Registro completado exitosamente!",
        description:
          "Haga clic en el botón a continuación y luego siga los pasos para confirmar su email.",
        button: "Ok",
      },
      emailResent: {
        title: "E-mail reenviado com sucesso!",
        description:
          "Reenviamos el código al email registrado, revisamos su correo electrónico, copiamos el código de confirmación, hacemos clic en el botón a continuación y seguimos los pasos para confirmar su email.",
        button: "Ok",
      },
      registerError: {
        button: "Ok",
      },
      homeBannersImage: {
        tooltip: "No mostrar más este banner",
      },
      closeAllBanners: {
        button: "No mostrar más estos banners",
      },
      homeBanners: {
        notification: "El banner ya no se mostrará",
        all_banners_notification: "Los Banners ya no se mostrarán",
      },
      newsList: {
        news: "Noticias",
        view_more: "Ver más",
      },
      recentNews: {
        no_news: "No hay noticias recientes para mostrar",
      },
      newsCard: {
        reading_time: "Tiempo estimado de lectura",
      },
      notificationsButton: {
        tooltip: "Notificaciones",
      },
      noNotifications: {
        recent_message: "No hay notificaciones recientes",
        message: "No notificaciones",
      },
      unreadNotificationItem: {
        mark_as_read: "Marcar como leído",
      },
      unreadNotifications: {
        notifications: "Notificaciones",
      },
      unreadNotificationsActions: {
        view_all: "Ver todo",
        mark_all_as_read: "Marcar todo como leido",
      },
      notificationCard: {
        new: "Nuevo",
        mark_as_read: "Marcar como leído",
        created: "Fecha de publicación",
        read: "Fecha de lectura",
      },
      notificationNotFound: {
        message: "Notificación no encontrada",
      },

      noRowsMessage: {
        no_permission: "No tienes permiso para acceder a esta información",
        no_data: "No hay datos para mostrar",
      },
      examsDownload: {
        open_report: "Abrir inform",
        report: "Informe",
      },
      examsPdf: {
        no_data: "Archivo no encontrado o vacío",
      },
      examsRedirectFile: {
        download_image: "Descargar imagen(imágenes)",
      },
      pagesActions: {
        previous_page: "Página anterior",
        next_page: "Próxima página",
        zoom_out: "Disminuir zoom",
        zoom_in: "Aumentar zoom",
        pages: "Página {{pageNumber}} de {{numPages}}",
        download_pdf: "Descargar PDF",
        print: "Imprimir",
      },
      accessTemporaryMessage: {
        access_temporary: "Acceso temporal",
        register:
          "Regístrese para acceder a todos los recursos de nuestro portal",
        login:
          "Inicie sesión para acceder a todos los recursos de nuestro portal",
        register_btn: "Registro",
        login_btn: "Acceso",
      },
      columnsSelect: {
        visible_columns: "Columnas visibles",
        subtitle: "Seleccione las columnas que desea ver",
        select_all: "Seleccionar todo",
      },
      procedureType: {
        all: "Todo",
        laboratorial: "Laboratorio",
        no_laboratorial: "No laboratorio",
      },
      dateRange: {
        start_date: "Fecha de inicio",
        end_date: "Fecha final",
      },
      renderPDF: {
        error: "Error al cargar el archivo",
      },
      examsRedirectModal: {
        title: "Imagen(s)",
      },
      fileItem: {
        download_file: "Descargar",
        preview: "Ver",
        image: "Imagen",
        open_new_tab: "Abrir archivo en una nueva pestaña",
      },
      accessPortalBtn: {
        button: "Acceso al portal",
      },
      formSucceeded: {
        title: "¡Gracias por su participación!",
        subtitle:
          "Su respuesta a nuestro formulario es muy valiosa para nosotros.",
        button: "Ir al portal",
        view_answers: "Ver respuestas",
      },
      formNotFound: {
        message: "Formulario no encontrado",
        subtitle: "Por favor revisa la URL y vuelve a intentarlo",
        button: "Ir al portal",
      },
      formStepsActions: {
        back: "Volver",
        next: "Próximo",
        end: "Finalizar",
      },
      step: {
        answer_success: "Respuesta enviada con éxito",
        update_answer_success: "Respuesta actualizada con éxito",
        errors: {
          alternative: "La alternativa es obligatoria",
        },
      },
      whatsappNumber: {
        title: "Número de WhatsApp",
        whatsapp_number: "Número de WhatsApp",
        success: "Número de WhatsApp actualizado con éxito",
        cancel: "Cancelar",
        save: "Guardar",
        errors: {
          whatsapp_number: "El número de WhatsApp es obligatorio",
        },
      },
      reports: {
        reports: "Informes",
        view_reports: "Ver informes",
        back: "Volver",
        file: "Archivo",
        view: "Ver",
        download: "Descargar",
      },
      checkInButton: {
        check_in: "Realizar check in",
        checked_in: "El check in ya se ha realizado, diríjase a la ubicación:",
        location:
          "Para realizar el check in, debes permitir el acceso a la ubicación",
        appointment_canceled:
          "Cita cancelada, no es posible realizar el check-in",
        is_late: "La fecha límite de check in ha pasado",
        is_in_time: "El check-in solo se puede realizar {{time}} minutos antes",
        is_near: "Debes estar a menos de {{meters}}m para realizar el Check in",
      },
      checkedIn: {
        message: "Check in exitoso, diríjase a la ubicación:",
      },
      reportsModal: {
        loading: "Cargando...",
        not_found: "Archivo no encontrados",
        file: "Archivo",
        actions: {
          open: "Abrir",
          view: "Ver",
          download: "Descargar",
        },
      },
      nonTasyRegister: {
        finish_register: "Finalizar Registro",
        name: "Nombre",
        whatsapp_number: "Número de WhatsApp",
        success: "Registro completado con éxito",
        helpers: {
          frontDoc: {
            title: "Foto Frontal",
            subtitle:
              "Toma una fotografía del frente de tu documento de identidad o licencia de conducir, el lado donde aparece tu foto.",
          },
          backDoc: {
            title: "Atrás Foto",
            subtitle:
              "Toma una fotografía del reverso de tu documento de identidad o licencia de conducir, el lado que solo contiene información.",
          },
          selfie: {
            title: "Selfie",
            subtitle:
              "Toma una foto de tu cara para que podamos identificarte. Busca lugares con buena iluminación y evita el uso de gafas y/o gorras.",
          },
          selfieDoc: {
            title: "Selfie con el documento",
            subtitle:
              "Tome una foto de su rostro sosteniendo su identificación con fotografía junto a su rostro. Busca lugares con buena iluminación y evita el uso de gafas y/o gorras.",
          },
        },
        photosSection: {
          title: "Envía tus documentos",
          front_doc: "Frente del Documento Fotográfico",
          back_doc: "Documento Fotográfico Atrás",
          selfie: "Selfie",
          selfie_doc: "Selfie con el documento",
          helper:
            "* El envío de documentos es importante para que nuestro equipo pueda validar y confirmar su registro. El envío es seguro y sigue las directrices LGPD.",
        },
        actions: {
          back: "Volver",
          register: "Registrar",
        },
        errors: {
          name: "El nombre es obligatorio",
          whatsapp_number: "El número de WhatsApp es obligatorio",
          photos: "Las fotos son obligatorias",
        },
      },
    },
    logout: "Cerrar sesión",
    whatsapp_number: "Numero WhatsApp",
    change_password: "Cambiar la contraseña",
    change_language: "Cambiar idioma",
    access_denied: "Necesita estar registrado para acceder a esta página",
  },
};

export default es;
