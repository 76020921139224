import { Box, Divider, Grid, Skeleton } from "@mui/material";
import React, { memo } from "react";

export const Loading = memo(() => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Skeleton variant="rounded" width="100%" height={60} />
      <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
        <Skeleton variant="rounded" width={150} height={25} />
        <Grid container spacing={2} mt={1}>
          {Array.from({ length: 3 }).map((_, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={4}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Skeleton variant="rounded" width={200} height={30} sx={{ mt: 2 }} />
        <Divider sx={{ my: 2 }} />
        <Skeleton variant="rounded" width={150} height={25} />
        <Grid container spacing={2} mt={1}>
          {Array.from({ length: 3 }).map((_, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={3}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton variant="rounded" width="100%" height={28} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Skeleton variant="rounded" width={100} height={35} sx={{ mt: 2 }} />
      </Box>
    </Box>
  );
});
