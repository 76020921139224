import { AutoComplete, ISelectType } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import ContactValue from "./ContactValue";

//TODO IMPROVE PERFORMANCE
interface IIndivudalContactProps {
  index: number;
  contactTypeList: ISelectType[];
}

const translation_path = "page.register.individuals.";

const IndividualContact: React.FC<IIndivudalContactProps> = ({
  index,
  contactTypeList,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={4}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translation_path + "contact_type")}
          name={`contacts.${index}.contactType`}
          options={contactTypeList}
        />
      </Grid>
      <ContactValue index={index} />
    </>
  );
};

export default memo(IndividualContact);
