import { Button } from "@4uhub/lib4uhub";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IActionsProps } from "./models";

const translationPath = "page.myRegistration.";

export const Action = memo(({ loading }: IActionsProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const cancelHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box
      sx={{
        pl: 0.5,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        position: "absolute",
        bottom: 10,
        zIndex: 10,
      }}
    >
      <Box
        sx={(t) => ({
          p: 1,
          width: "fit-content",
          display: "flex",
          gap: 1,
          backgroundColor: t.palette.background.paper,
          boxShadow: t.shadows[2],
          borderRadius: t.shape.borderRadius,
        })}
      >
        <Button
          size="small"
          variant="contained"
          type="submit"
          startIcon={<CheckRoundedIcon />}
          loading={loading}
        >
          {t(`${translationPath}save`)}
        </Button>
        <Button
          size="small"
          variant="outlined"
          startIcon={<CloseRoundedIcon />}
          disabled={loading}
          onClick={cancelHandler}
        >
          {t(`${translationPath}cancel`)}
        </Button>
      </Box>
    </Box>
  );
});
