import { addressSchema } from "@4uhub/lib4uhub";
import { z } from "zod";
import {
  CEL_TEL_VALIDATOR,
  EMAIL_VALIDATOR,
  SITE_VALIDATOR,
} from "../../utils/Validators/regexValidators";

const translationPath = "page.myRegistration.";

const contactTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: "O tipo do contato é obrigatório" }
);

const contactSchema = z
  .object({
    id: z.string().optional(),
    contactTypeId: z.string().optional(),
    contactType: contactTypeSchema,
    otherType: z.string().nullable(),
    value: z.string(),
  })
  .superRefine((val, ctx) => {
    if (val.contactType.code) {
      const code = val.contactType.code;
      if (code === "1" || code === "2") {
        if (!CEL_TEL_VALIDATOR.test(val.value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translationPath + "contact.phone",
          });
        }
      }
      if (code === "3" || code === "4") {
        if (!EMAIL_VALIDATOR.test(val.value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translationPath + "contact.email",
          });
        }
      }
      if (code === "5") {
        if (!SITE_VALIDATOR.test(val.value))
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translationPath + "contact.site",
          });
      }
      if (code === "6") {
        if (!val.value) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["value"],
            message: translationPath + "contact.other",
          });
        }
      }
    }
  });

export const myRegistrationSchema = z.object({
  contacts: z.array(contactSchema).min(1, "Ao menos um contato é obrigatório"),
  addressess: z.array(addressSchema),
});

export type TMyRegistrationForm = z.infer<typeof myRegistrationSchema>;
