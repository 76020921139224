import {
  AddressForm,
  FormArray,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IGeneric } from "../../../models/generics";
import ExternalService from "../../../services/external.service";
import MainApiService from "../../../services/generic.service";
import { deleteAddress } from "../../../services/individual.service";
import { TMyRegistrationForm } from "../myRegistrationSchema";

const translation_path = "page.register.individuals.";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const service = new MainApiService<IGeneric>(GENERICS_ROUTE);

const NEIGHBORHOOD_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Neighborhood/";
const neighborhoodService = new MainApiService<ISelectType>(NEIGHBORHOOD_ROUTE);

const cepService = new ExternalService();

const IndividualAdresses: React.FC = () => {
  const { t } = useTranslation();

  const { control, setValue, getValues, formState } =
    useFormContext<TMyRegistrationForm>();

  const [addressesTypeList, setAddressesTypeList] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(service.list);

  const { sendRequest: deleteCurrentAddress } = useFetch(deleteAddress);

  const loadAddressTypes = useCallback(async () => {
    const { data } = await sendRequest({ identifier: "IndividualAddressType" });
    if (data) {
      setAddressesTypeList(
        data.sort((a, b) =>
          a.code! < b.code! ? -1 : a.code! > b.code! ? 1 : 0
        )
      );
    }
  }, [sendRequest]);

  const handleDeleteAddress = (id: string, secondId: string) => {
    return deleteCurrentAddress(secondId);
  };

  useEffect(() => {
    loadAddressTypes();
  }, [loadAddressTypes]);

  const addressesFieldArray = useFieldArray<
    TMyRegistrationForm,
    "addressess",
    "key"
  >({
    control,
    name: "addressess",
    keyName: "key",
  });

  const getAvailableAddressesTypeList = useMemo(
    () =>
      (index: number): ISelectType[] => {
        const selectedTypes = addressesFieldArray.fields
          .map((field, idx) => idx !== index && field.addressType.id)
          .filter(Boolean);

        return addressesTypeList
          .filter(
            (type) => type.code === "7" || !selectedTypes.includes(type.id)
          )
          .map((type) => ({
            id: type.id,
            name: type.name || "",
            code: type.code || "",
          }));
      },
    [addressesFieldArray, addressesTypeList]
  );

  const ensureSelectedAddressType = useCallback(
    (index: number): void => {
      const selectedAddressTypeId =
        addressesFieldArray.fields[index]?.addressType.id;

      const availableTypes = getAvailableAddressesTypeList(index);

      const currentValue = getValues(`addressess.${index}.addressType`);

      if (!availableTypes.some((type) => type.id === selectedAddressTypeId)) {
        if (currentValue && currentValue.id.length > 0) {
          setValue(`addressess.${index}.addressType`, currentValue);
        } else {
          setValue(
            `addressess.${index}.addressType`,
            availableTypes[0] || { id: "", name: "", code: "" }
          );
        }
      }
    },
    [addressesFieldArray, getAvailableAddressesTypeList, setValue, getValues]
  );

  useEffect(() => {
    addressesFieldArray.fields.forEach((_, index) => {
      ensureSelectedAddressType(index);
    });
  }, [ensureSelectedAddressType, addressesFieldArray, setValue]);

  return (
    <Grid item xs={12}>
      <FormArray
        title={t(translation_path + "addresses")}
        name="addresses"
        errorMessage={formState.errors?.addressess?.message}
        onDelete={handleDeleteAddress}
        isOnDeleteWithoutId
        addButtonLabel={t(translation_path + "add_address") || "Add Address"}
        formArray={addressesFieldArray}
        appendValue={{
          addressType: { id: "", name: "", code: "" },
          otherType: "",
          zipCode: "",
          otherNeighborhood: false,
          addressDescription: "",
          neighborhoodName: "",
          number: "",
          complement: "",
        }}
        fieldsObject={(index) => {
          const addressType = `addressess.${index}.addressType`;
          const zipCode = `addressess.${index}.zipCode`;
          const city = `addressess.${index}.city`;
          const neighborhood = `addressess.${index}.neighborhood`;
          const otherNeighborhood = `addressess.${index}.otherNeighborhood`;
          const provincy = `addressess.${index}.provincy`;
          const addressDescription = `addressess.${index}.addressDescription`;
          const neighborhoodName = `addressess.${index}.neighborhoodName`;
          const number = `addressess.${index}.number`;
          const complement = `addressess.${index}.complement`;

          return (
            <AddressForm
              cepRequest={cepService.getCep}
              neighborhoodRequest={neighborhoodService.list}
              addressesTypeList={getAvailableAddressesTypeList(index)}
              addressType={addressType}
              zipCode={zipCode}
              city={city}
              neighborhood={neighborhood}
              otherNeighborhood={otherNeighborhood}
              provincy={provincy}
              addressDescription={addressDescription}
              neighborhoodName={neighborhoodName}
              number={number}
              complement={complement}
            />
          );
        }}
      />
    </Grid>
  );
};

export default IndividualAdresses;
