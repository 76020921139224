import { useFetch } from "@4uhub/lib4uhub";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import { Box, Typography } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDownload from "../../../../../hooks/useDownload";
import {
  getLaboratorialExamsFile,
  getLaboratorialExamsFileAsTemporary,
} from "../../../../../services/laboratorialExams.service";
import {
  getAllNonLaboratorialExams,
  getAllNonLaboratorialExamsTasy,
} from "../../../../../services/nonLaboratorialExams.service";
import { tokenTemporaryService } from "../../../../../services/tokenTemorary.service";
import { Actions } from "./Actions/Actions";
import { IFileItemProps } from "./models";

const translationPath = "components.reportsModal.";

export const FileItem = memo(
  ({
    index,
    identifier,
    isActive,
    selectedFile,
    origin,
    onSelectFile,
    onFileLoading,
    onGetFileIndex,
  }: IFileItemProps) => {
    const { t } = useTranslation();

    const isTemporaryAccess = !!tokenTemporaryService.getTemporaryAccessToken();

    const { sendRequest: getLaboratorialExam, loading: labExamLoading } =
      useFetch(
        isTemporaryAccess
          ? getLaboratorialExamsFileAsTemporary
          : getLaboratorialExamsFile
      );

    const { sendRequest: getNonLaboratorialExam, loading: nonLabExamLoading } =
      useFetch(
        isTemporaryAccess
          ? getAllNonLaboratorialExamsTasy
          : getAllNonLaboratorialExams
      );

    const fetchLaboratorialExam = useCallback(async () => {
      const { data, success } = await getLaboratorialExam({
        Identifier: identifier,
        ImageIdentifier:
          origin.sequencialImageControl && origin.sequencialImageControl > 0
            ? origin.sequencialImageControl
            : 1,
      });
      if (data && success) {
        onSelectFile(data);
        onGetFileIndex(index);
      } else {
        onSelectFile(null);
      }
    }, [
      identifier,
      index,
      origin.sequencialImageControl,
      onGetFileIndex,
      onSelectFile,
      getLaboratorialExam,
    ]);

    const fetchNonLaboratorialExam = useCallback(async () => {
      if (!origin.sequencialPdfControl) return;

      const { data, success } = await getNonLaboratorialExam({
        identifier,
        pdfIdentifier: origin.sequencialPdfControl,
      });
      if (data && success) {
        const file = data.length > 0 ? data[0].medicalCertificateFile : null;
        if (file) {
          onSelectFile(file);
        } else {
          onSelectFile(null);
        }
        onGetFileIndex(index);
      } else {
        onSelectFile(null);
      }
    }, [
      identifier,
      index,
      origin.sequencialPdfControl,
      getNonLaboratorialExam,
      onSelectFile,
      onGetFileIndex,
    ]);

    const selectFileHandler = useCallback(() => {
      onGetFileIndex(index);
    }, [onGetFileIndex, index]);

    const { downloadBase64File } = useDownload();

    const handleDownloadFile = useCallback(() => {
      if (!selectedFile) return;
      downloadBase64File(
        selectedFile,
        `${t(`${translationPath}file`)} ${index + 1}`
      );
    }, [index, selectedFile, downloadBase64File, t]);

    useEffect(() => {
      onFileLoading(labExamLoading);
    }, [onFileLoading, labExamLoading]);

    useEffect(() => {
      if (isActive) {
        if (origin.origin === "LAUDO_PACIENTE") {
          fetchLaboratorialExam();
        }
        if (origin.origin === "LAUDO_PACIENTE_PDF") {
          fetchNonLaboratorialExam();
        }
      }
    }, [
      fetchLaboratorialExam,
      fetchNonLaboratorialExam,
      isActive,
      origin.origin,
    ]);

    const isLoading = labExamLoading || nonLabExamLoading;

    return (
      <Box
        sx={(t) => ({
          p: 1,
          height: "fit-content",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 15,
          cursor: isActive ? undefined : "pointer",
          transition: "ease-in-out 0.2s",
          "&:hover": isActive
            ? undefined
            : {
                backgroundColor:
                  t.palette.grey[t.palette.mode === "light" ? 100 : 900],
                borderRadius: 2,
              },
        })}
        onClick={isActive ? undefined : selectFileHandler}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box
            sx={(t) => ({
              p: 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: isActive
                ? t.palette.primary.main
                : "transparent",
              color: isActive ? t.palette.primary.contrastText : "inherit",
              borderRadius: 15,
            })}
          >
            <AttachFileRoundedIcon fontSize="small" />
          </Box>
          <Typography>
            {t(`${translationPath}file`)} {index + 1}
          </Typography>
        </Box>
        <Actions
          viewHandler={selectFileHandler}
          funcHandler={
            origin.origin === "LAUDO_PACIENTE"
              ? fetchLaboratorialExam
              : fetchNonLaboratorialExam
          }
          handleDownloadFile={handleDownloadFile}
          isLoading={isLoading}
          isActive={isActive}
          selectedFile={selectedFile}
        />
      </Box>
    );
  }
);
