import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { MenuItem, Stack, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const translationPath = "page.myRegistration.";

export const MyRegistration = memo(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const clickHandler = useCallback(() => {
    navigate("/my-registration");
  }, [navigate]);

  return (
    <MenuItem onClick={clickHandler}>
      <Stack direction={"row"} spacing={1}>
        <PersonRoundedIcon color="action" />
        <Typography>{t(`${translationPath}my_registration_menu`)}</Typography>
      </Stack>
    </MenuItem>
  );
});
