import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ISchedule, IScheduleCheckInRule } from "../model";
import { CheckInButton } from "../MySchedules/CheckInButton/CheckInButton";
import { Calendar } from "./Calendar";
import { Type } from "./Type";

const ScheduleItem = ({
  data,
  checkInRule,
}: {
  data: ISchedule;
  checkInRule: IScheduleCheckInRule;
}) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(
      `/schedule/mySchedules/${data.tasySchedulingType.code}/${data.identifier}`
    );
  };

  return (
    <Stack
      sx={(t) => ({
        flexDirection: "row",
        gap: t.spacing(2),
        padding: t.spacing(2),
        backgroundColor: t.palette.divider,
        borderRadius: t.shape.borderRadius / 2,
      })}
      onClick={onClickHandler}
    >
      <Calendar date={data.calendarDate} />
      <Stack
        sx={(t) => ({
          justifyContent: "space-between",
          flexGrow: 1,
          padding: t.spacing(1),
          flex: 1,
        })}
      >
        <Stack
          sx={(t) => ({
            flexDirection: "row",
            justifyContent: "space-between",
            gap: t.spacing(1),
            alignItems: "center",
          })}
        >
          <Typography style={{ flex: 1, fontWeight: "500" }}>
            {data.descriptionSite}
          </Typography>
          {data.tasySchedulingType.code && (
            <Type type={data.tasySchedulingType.code} />
          )}
        </Stack>
        <Typography style={{ alignSelf: "flex-end", fontSize: 15 }}>
          {data.pacientName}
        </Typography>
        {checkInRule && (
          <CheckInButton
            calendarStatusCode={data.calendarStatusCode}
            checkInRule={checkInRule}
            encounterIdentifier={data.encounterIdentifier}
            roomName={data.roomName}
            scheduleDate={data.calendarStartDate}
            scheduleTypeCode={data.tasySchedulingType.code!}
            identifier={data.identifier}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ScheduleItem;
