import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { IDataProps } from "./models";

export const Data = memo(({ label, value }: IDataProps) => {
  return (
    <Box>
      <Typography
        variant="subtitle2"
        sx={(t) => ({
          color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
        })}
      >
        {label}:
      </Typography>
      <Typography>{value ?? "-"}</Typography>
    </Box>
  );
});
