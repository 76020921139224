import { memo, useCallback } from "react";
import { Stack, Typography } from "@mui/material";

import { IHealthProgram } from "../../../models/health-programs";
import HealthProgramItemHeader from "./HealthProgramItemHeader/HealthProgramItemHeader";
import HealthProgramItemContent from "./HealthProgramItemContent";
import { defaultScrollTheme } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";

interface IHealthProgramProps {
  program: IHealthProgram;
}

const HealthProgram = ({ program }: IHealthProgramProps) => {
  const hasImage = program.contentsHealthProgram.length > 0;

  const navigate = useNavigate();
  const navigateToProgram = useCallback(
    (contentId?: string) => {
      if (contentId) {
        navigate(program.id + "/" + contentId);
      } else {
        navigate(program.id);
      }
    },
    [navigate, program.id]
  );

  return (
    <Stack gap={2}>
      <HealthProgramItemHeader program={program} />
      {hasImage && (
        <Stack
          flexDirection={"row"}
          flexWrap={"nowrap"}
          gap={2}
          sx={defaultScrollTheme}
          padding={1}
          overflow={"auto"}
          width={"100%"}
        >
          {program.contentsHealthProgram.map((content) => (
            <HealthProgramItemContent
              content={content}
              onClick={() => navigateToProgram(content.id)}
              key={content.content.id}
            />
          ))}
        </Stack>
      )}
      {!hasImage && (
        <Typography
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
        >
          {program.description}
        </Typography>
      )}
    </Stack>
  );
};

export default memo(HealthProgram);
