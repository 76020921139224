import { Pagination, Stack } from "@mui/material";
import { memo } from "react";

import { IExams } from "../../models/exams";
import ExamItem from "./ExamItem";

interface IExamsListProps {
  data: IExams[];
  page: number;
  onPageChange: (page: number) => void;
  count: number;
  individualId?: string;
}

const ExamsList: React.FC<IExamsListProps> = ({
  data,
  page,
  onPageChange,
  count,
  individualId,
}) => {
  return (
    <Stack gap={2} mt={2}>
      {data.map((exam) => (
        <ExamItem key={exam.id} data={exam} individualId={individualId} />
      ))}
      <Pagination
        count={count}
        page={page}
        sx={{ alignSelf: "center" }}
        onChange={(_, page) => onPageChange(page)}
      />
    </Stack>
  );
};

export default memo(ExamsList);
