import LogoutIcon from "@mui/icons-material/Logout";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useConfirm } from "material-ui-confirm";
import { Divider, Stack } from "@mui/material";

import { IHealthProgramDetail } from "../../models/health-programs";
import PagesContainer from "../../components/UI/PagesContainer";
import Button from "../../components/UI/Button";
import useFetch from "../../hooks/useFetch";
import {
  getHealthProgramById,
  leaveProgram,
} from "../../services/healthPrograms.service";
import NoData from "../../components/UI/NoData";
import Loading from "../../components/Loading";
import useRole from "../../hooks/useRole";
import usePermission from "../../hooks/usePermission";
import { tokenService } from "../../services/token.service";
import HealthProgramContentItem from "../../components/HealthPrograms/HealthProgramContent/HealthProgramContentItem";
import { Domains } from "../../models/permission";

const translationPath = "page.health_programs.";

const HealthProgram = () => {
  const { t } = useTranslation();

  const token = !!tokenService.getAccessToken();

  const confirm = useConfirm();

  const { contentId } = useParams();

  const hasPermission = usePermission({
    domain: Domains.HEALTH_PROGRAM,
    actions: ["Read"],
  });

  const canLeave = useRole(["Update"]);

  const { id } = useParams();

  const navigate = useNavigate();

  const [healthProgram, setHealthProgram] = useState<IHealthProgramDetail>();

  const { sendRequest, loading } = useFetch(getHealthProgramById);

  const { sendRequest: leave, loading: leaveLoading } = useFetch(leaveProgram);

  const fetchHealthProgram = useCallback(async () => {
    if (!id) return;
    const { data, success } = await sendRequest(id);
    if (data && success) {
      setHealthProgram(data);
    }
  }, [sendRequest, id]);

  const leaveRequest = useCallback(async () => {
    if (!id) return;
    const { success } = await leave(id);
    if (success) {
      navigate("/health-programs");
    }
  }, [navigate, leave, id]);

  const leaveProgramHandler = () => {
    confirm({
      title: t(translationPath + "exitProgram.title"),
      description: t(translationPath + "exitProgram.description"),
      confirmationText: t(translationPath + "exitProgram.button1"),
      cancellationText: t(translationPath + "exitProgram.button2"),
    })
      .then(leaveRequest)
      .catch(() => {});
  };

  useEffect(() => {
    fetchHealthProgram();
  }, [fetchHealthProgram]);

  useEffect(() => {
    if (contentId && healthProgram) {
      const element = document.getElementById(contentId);

      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [contentId, healthProgram]);

  if (loading) return <Loading />;

  if (!healthProgram) return <NoData />;

  if (!hasPermission && token) {
    return <Navigate to={`/no-access`} />;
  }

  const item = healthProgram?.healthProgramContentRules[0]?.contentConfigs;

  return (
    <PagesContainer
      title={healthProgram.name ?? t(translationPath + "title")}
      subtitle={healthProgram.description}
      rowActions={
        canLeave ? (
          <Button
            size="small"
            variant="outlined"
            startIcon={<LogoutIcon />}
            onClick={leaveProgramHandler}
            loading={leaveLoading}
          >
            {t(translationPath + "exit")}
          </Button>
        ) : undefined
      }
    >
      <PagesContainer paddinRight={2} hideBackButton>
        {!item && <NoData />}

        {item && (
          <Stack gap={1} width={"100%"} sx={{ pt: 5 }}>
            {item.map((content, index) => (
              <Fragment key={content.id}>
                <HealthProgramContentItem content={content} />
                {index !== item.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Stack>
        )}
      </PagesContainer>
    </PagesContainer>
  );
};

export default HealthProgram;
