import { Button, useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box } from "@mui/material";
import { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { updateAcceptance } from "../../../services/notifications.service";
import { IAcceptanceButtonProps } from "./models";

export const AcceptanceButton = memo(
  ({ onHideAcceptanceButton }: IAcceptanceButtonProps) => {
    const { id } = useParams();

    const { setMessage } = useNotificationContext();

    const { sendRequest, loading } = useFetch(updateAcceptance);

    const acceptanceHandler = useCallback(async () => {
      if (!id) return;
      const { data, success } = await sendRequest(id);
      if (data && success) {
        setMessage({
          message: "Aceite realizado com sucesso!",
          type: "success",
        });
        onHideAcceptanceButton(false);
      }
    }, [sendRequest, setMessage, onHideAcceptanceButton, id]);

    return (
      <Box
        sx={{
          mt: 2,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          startIcon={<CheckCircleRoundedIcon />}
          loading={loading}
          onClick={acceptanceHandler}
        >
          Estou ciente, quero voltar a para a tela inicial
        </Button>
      </Box>
    );
  }
);
