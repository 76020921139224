import { useFetch } from "@4uhub/lib4uhub";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Box, CircularProgress, Popover, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { getAlertTypeUser } from "../../../services/alert.service";
import { hexToRgbA } from "../../../utils/utils";
import IconButton from "../IconButton";

export const Alert = memo(() => {
  const [open, setOpen] = useState(false);

  const [message, setMessage] = useState<string | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { sendRequest, loading } = useFetch(getAlertTypeUser);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const fetchAlertType = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (data && success) {
      if (!data.length) return;
      const alertMessage = data.find((d) => d.alertType.code === "1");
      if (!alertMessage) return;
      setMessage(alertMessage.description);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchAlertType();
  }, [fetchAlertType]);

  if (loading) {
    return <CircularProgress size={17} sx={{ mr: 1 }} />;
  }

  if (!message) return null;

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={(t) => ({
            p: 2,
            maxWidth: 300,
            display: "flex",
            flexDirection: "row",
            gap: 2,
            background: `linear-gradient(0deg, #fff 0%, ${hexToRgbA(
              t.palette.error.main,
              0.2
            )} 100%)`,
          })}
        >
          <Box
            sx={(t) => ({
              p: 0.5,
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: t.palette.background.default,
              borderRadius: "50%",
              boxShadow: t.shadows[1],
            })}
          >
            <WarningRoundedIcon color="error" />
          </Box>
          <Typography variant="subtitle2">{message}</Typography>
        </Box>
      </Popover>
      <IconButton
        size="small"
        disabled={loading}
        loading={loading}
        onClick={handleClick}
      >
        <WarningRoundedIcon color="error" />
      </IconButton>
    </>
  );
});
