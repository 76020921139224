import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { memo, useCallback, useState } from "react";

import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";

import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { tokenService } from "../../../services/token.service";
import { tokenTemporaryService } from "../../../services/tokenTemorary.service";
import { userService } from "../../../services/user.service";
import { configurationsSliceActions } from "../../../store/slices/configurations";
import { permissionSliceActions } from "../../../store/slices/permission";
import { privilegeSliceActions } from "../../../store/slices/privilege";
import { userSliceActions } from "../../../store/slices/user";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import ChangeLanguageForm from "../../ChangeLanguageForm";
import ModalComponent from "../Modal";
import { GlobeIcon } from "./DynamicIcon";
import MenuDrawer from "./MenuDrawer/MenuDrawer";
import { MyRegistration } from "./MyRegistration/MyRegistration";
import { WhatsappNumber } from "./WhatsappNumber/WhatsappNumber";
import { WhatsappNumberModal } from "./WhatsappNumber/WhatsappNumberModal/WhatsappNumberModal";

const AvatarDropdown = () => {
  const { fullName, email } = useAppSelector((state) => state.user);

  const [openWhatsModal, setOpenWhatsModal] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const temporaryName = tokenTemporaryService.getTemporaryAccessName();

  const isTemporary = tokenTemporaryService.hasTemporaryAccessToken();

  const isLogged = !!tokenService.hasAccessTokens();

  const dispatch = useAppDispatch();

  const { removeUserName } = userService;

  const { removeAllTokens } = tokenService;

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  const theme = useTheme();

  const hasUser = fullName || isTemporary;

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const openModalHandler = () => setOpenModal(true);

  const closeModalHandler = () => setOpenModal(false);

  const openWhatsModalHandler = useCallback(() => {
    setOpenWhatsModal(true);
  }, []);

  const closeWhatsModalHandler = useCallback(() => {
    setOpenWhatsModal(false);
  }, []);

  const handleClick = (event: any) => {
    if (hasUser) setAnchorEl(event.currentTarget);
    if (!hasUser) navigate("/login");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = useCallback(() => {
    if (isLogged) {
      removeAllTokens();
      removeUserName();
      dispatch(userSliceActions.removeUser());
      dispatch(privilegeSliceActions.removePrivilege());
      dispatch(permissionSliceActions.removePermission());
      dispatch(configurationsSliceActions.removeConfigs());
      navigate("/login", { replace: true });
    } else {
      tokenTemporaryService.removeTemporaryAccessName();
      tokenTemporaryService.removeTemporaryAccessToken();
      navigate("/", { replace: true });
    }
  }, [navigate, dispatch, removeAllTokens, removeUserName, isLogged]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  const userAvatar = isLogged
    ? fullName
    : isTemporary && temporaryName
    ? temporaryName
    : null;

  return (
    <Box>
      <MenuDrawer
        open={drawerOpen}
        toggleMenu={setDrawerOpen}
        openModalHandler={openModalHandler}
      />
      {openWhatsModal && (
        <WhatsappNumberModal
          open={openWhatsModal}
          onClose={closeWhatsModalHandler}
        />
      )}
      <ModalComponent
        open={openModal}
        onClose={closeModalHandler}
        dialogMaxWidth={"md"}
      >
        <ChangeLanguageForm onClose={closeModalHandler} />
      </ModalComponent>
      <Tooltip
        title={
          hasUser
            ? t("components.avatarDropdown.tooltip_user")
            : t("components.avatarDropdown.tooltip_visit")
        }
        placement="left-end"
      >
        <Button
          onClick={!matches && hasUser ? toggleDrawer(true) : handleClick}
          size="small"
          sx={(theme) => ({
            padding: 1,
            borderRadius: theme.shape.borderRadius,
          })}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="menu"
          aria-expanded={open ? "true" : undefined}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Avatar sx={{ width: 40, height: 40 }}>
              {userAvatar ? userAvatar.charAt(0).toUpperCase() : <PersonIcon />}
            </Avatar>

            {matches && (fullName || isTemporary) && (
              <motion.div
                animate={{ rotate: open ? 180 : 0 }}
                transition={{ type: "tween", duration: 0.2, ease: "easeOut" }}
                style={{ height: "24px" }}
              >
                <KeyboardArrowDownIcon color={"primary"} />
              </motion.div>
            )}
          </Stack>
        </Button>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {userAvatar && (
          <Box>
            <Stack
              px={2}
              py={1}
              direction={"row"}
              alignItems={"center"}
              spacing={1}
            >
              <Avatar sx={{ width: 45, height: 45 }}>
                {userAvatar ? (
                  userAvatar.charAt(0).toUpperCase()
                ) : (
                  <PersonIcon />
                )}
              </Avatar>
              <Stack>
                <Typography fontSize={18}>
                  {fullName || temporaryName}
                </Typography>
                <Typography
                  sx={(theme) => ({
                    color:
                      theme.palette.mode === "light"
                        ? theme.palette.grey[600]
                        : theme.palette.grey[400],
                  })}
                >
                  {email}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ mb: 1 }} />
          </Box>
        )}
        <MenuItem onClick={openModalHandler}>
          <Stack direction={"row"} spacing={1}>
            <SvgIcon component={GlobeIcon} fontSize={"medium"} color="action" />
            <Typography>{t("change_language")}</Typography>
          </Stack>
        </MenuItem>
        {!isTemporary && (
          <Box>
            <MenuItem onClick={() => navigate("/change-password")}>
              <Stack direction={"row"} spacing={1}>
                <LockIcon color="action" />
                <Typography>{t("change_password")}</Typography>
              </Stack>
            </MenuItem>
            <MyRegistration />
            <MenuItem onClick={() => navigate("/docs")}>
              <Stack direction={"row"} spacing={1}>
                <HistoryEduIcon color="action" />
                <Typography> {t("page.documents.title")}</Typography>
              </Stack>
            </MenuItem>
          </Box>
        )}
        <WhatsappNumber openModalHandler={openWhatsModalHandler} />
        <MenuItem onClick={logout}>
          <Stack direction={"row"} spacing={1}>
            <LogoutIcon color="action" />
            <Typography>{t("logout")}</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default memo(AvatarDropdown);
