import GlobalAxios from "../axios/axios-config";
import {
  IGetIndividualData,
  IUpdateIndividualData,
} from "../models/individual";

const INDIVIDUAL_ROUTE = process.env.REACT_APP_APP + "/api/v1/Individual/";

export const getIndividualData = () => {
  return GlobalAxios.get<IGetIndividualData>(
    INDIVIDUAL_ROUTE + "GetIndividualData"
  );
};

export const updateIndividualData = (item: IUpdateIndividualData) => {
  return GlobalAxios.put<IGetIndividualData>(
    INDIVIDUAL_ROUTE + "UpdateIndividualData",
    item
  );
};

export const deleteAddress = (id: string) => {
  return GlobalAxios.delete(INDIVIDUAL_ROUTE + "Address/" + id);
};
