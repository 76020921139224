import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { hexToRgbA } from "../../../utils/utils";
import { Data } from "./Data/Data";
import { IUserDataProps } from "./models";

const translationPath = "page.myRegistration.";

export const UserData = memo(
  ({ name, document, birthDate }: IUserDataProps) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();

    const dateArray = birthDate ? birthDate.split("-") : [];

    const month = dateArray[1];

    const dateConverted =
      dateArray.length > 0
        ? dateArray[0] +
          "-" +
          `${month.length === 1 ? `0${month}` : month}` +
          "-" +
          dateArray[2] +
          "T15:00:00.00Z"
        : null;

    const date = dateConverted ? new Date(dateConverted) : null;

    return (
      <Box
        sx={(t) => ({
          p: 1,
          px: 2,
          height: "fit-content",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 4,
          border: `1px solid ${t.palette.divider}`,
          borderRadius: t.shape.borderRadius,
          position: "relative",
        })}
      >
        <Box
          sx={(t) => ({
            height: "100%",
            width: "100%",
            background: `linear-gradient(90deg, ${hexToRgbA(
              t.palette.primary.main,
              0.5
            )}  0%, ${hexToRgbA(
              t.palette.mode === "light"
                ? t.palette.background.default
                : t.palette.grey[900],
              1
            )} 8%)`,
            borderRadius: t.shape.borderRadius,
            position: "absolute",
            left: 0,
            zIndex: -1,
          })}
        />
        <Box
          sx={(t) => ({
            p: 1,
            width: "fit-content",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            borderRadius: "50%",
            boxShadow: 1,
            color: t.palette.grey[600],
          })}
        >
          <PersonRoundedIcon fontSize="large" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 4,
          }}
        >
          <Data label={t(`${translationPath}name`)} value={name} />
          <Data label={t(`${translationPath}document`)} value={document} />
          <Data
            label={t(`${translationPath}birth_date`)}
            value={
              date
                ? format(date, "P", {
                    locale:
                      language === "pt-BR"
                        ? ptBR
                        : language === "en-US"
                        ? enUS
                        : es,
                  })
                : "-"
            }
          />
        </Box>
      </Box>
    );
  }
);
