import EventIcon from "@mui/icons-material/Event";
import { Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { CheckInButton } from "../../MySchedules/CheckInButton/CheckInButton";
import { ITasySchedCheckInRule } from "../../model";

dayjs.extend(relativeTime);

interface IConfirmDateProps {
  startHour: Dayjs;
  duration: number;
  encounterIdentifier?: number | null;
  checkInRule?: ITasySchedCheckInRule;
  scheduleType?: string;
  scheduleDate?: string;
  roomName?: string | null;
  identifier?: number | null;
  calendarStatusCode: string | null;
}

const ConfirmDate: React.FC<IConfirmDateProps> = ({
  duration,
  startHour,
  checkInRule,
  scheduleType,
  scheduleDate,
  roomName,
  encounterIdentifier,
  calendarStatusCode,
  identifier,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      gap={1}
      padding={2}
      sx={(t) => ({
        borderRadius: t.shape.borderRadius,
        backgroundColor: t.palette.primary.main,
        color: "white",
      })}
    >
      <Stack gap={1} direction={"row"} alignItems={"center"}>
        <EventIcon />
        <Typography fontWeight={"bold"}>
          {t("page.schedules.newSchedule.confirm.schedule")}
        </Typography>
      </Stack>
      <Typography>{startHour.format("LLL")}</Typography>
      <Typography>{duration} min</Typography>
      <Typography variant="body2">{startHour.fromNow()}</Typography>
      {scheduleType &&
        scheduleDate &&
        roomName !== undefined &&
        encounterIdentifier !== undefined &&
        checkInRule && (
          <CheckInButton
            calendarStatusCode={calendarStatusCode}
            checkInRule={checkInRule}
            scheduleDate={scheduleDate}
            encounterIdentifier={encounterIdentifier}
            roomName={roomName}
            identifier={identifier!}
            scheduleTypeCode={scheduleType}
          />
        )}
    </Stack>
  );
};

export default memo(ConfirmDate);
