const en_US = {
  translations: {
    page: {
      schedules: {
        home: {
          schedules: "Schedules",
          nextSchedules: "Next appointments",
          noFound: "No schedule for the next few days",
          actions: {
            newSchedule: "New scheduling",
            mySchedules: "My appointments",
            familyGroup: "Family group",
            insurance: "My Insurance",
          },
        },
        scheduleDetails: {
          title: "Schedule details",
          professional: "Professional",
          type: "Type of scheduling",
          insurance: "Health insurance",
          reschedule: "Reschedule",
          patient: "Patient",
          confirmModal: {
            title: "Confirm agenda",
            description:
              "You must confirm the scheduling with {{min}} minutes in advance.",
          },
          status: "status",
          confirm: {
            button: "Confirm",
            confirmed: "Schedule confirmed",
          },
          cancel: {
            button: "Cancel",
            success: "Successful scheduling successfully",
            title: "Cancel scheduling?",
            title2: "Reset scheduling?",
            description2:
              "To react first you need to cancel the scheduling, fill out the reason below and then you can select a new appointment",
            description:
              "Do you confirm the cancellation of your scheduling day {{date}} with {{professional}}?",
            justification: {
              label: "Justification",
              placeholder: "Enter the reason to cancel the agenda",
              error: "The justification is mandatory",
            },
            reason: {
              label: "Reason for cancellation",
              placeholder: "Select the reason to cancel the agenda",
              error: "The reason is mandatory",
            },
            cancel: "No",
            confirm: "Yes, cancel",
          },
        },
        insurance: {
          title: "My Agreements",
          subtitle: "Manage your agreements and those of the family group",
          add: "To add",
          insuranceName: "Insurance",
          individual: "Individual",
          cardNumber: "Card number",
          cardValidity: "Validity",
          delete: {
            title: "Delete an agreement?",
            description: "This action is permanent!",
            confirmationText: "Yes, delete",
            cancellationText: "Cancel",
          },
          addInsurance: {
            edit: "Edit agreement",
            add: "Add Agreement",
            buttonSave: "To save",
            buttonEdit: "To edit",
            number: {
              label: "Card number (optional)",
              error: "The number is mandatory",
            },
            validity: {
              label: "Card Validity (optional)",
              error: "Validity is mandatory",
            },
            individual: {
              label: "Patient",
              error: "The patient is mandatory",
            },
            insurance: {
              label: "Health insurance",
              error: "The agreement is mandatory",
            },
          },
        },
        addFamilyGroup: {
          title: "Family group",
          birthDate: {
            label: "Date of birth",
            error: "The date of birth is mandatory",
          },
          kinshipDegree: {
            label: "Kinship",
            error: "Kinship is mandatory",
          },
          document: {
            label: "CPF",
            error: "The CPF is mandatory",
          },
          name: {
            label: "Name",
            error: "The name is mandatory",
          },
          nationality: {
            label: "Nationality",
            error: "Nationality is mandatory",
          },
          gender: {
            label: "Gender",
            error: "Gender is mandatory",
          },
          subtitle: "Manage members of your family group",
          add: "To add",
          individual: "Individual",
          delete: {
            title: "Delete?",
            description: "This action is permanent!",
            confirmationText: "Yes, delete",
            cancellationText: "Cancel",
          },
          titleAdd: "Add familiar",
          titleEdit: "Family Edit",
          edit: "Edit",
          save: "Save",
        },
        mySchedules: {
          title: "My Schedules",
          subtitle:
            "View your future schedules and the history of past schedules",
          next: "Next appointments",
          historic: "Historic",
          add: "Add",
          status: "status",
          date: "Data",
          type: {
            3: "Consultation",
            2: "Exam",
          },
          pacientName: "Patient",
          descriptionSite: "Description",
          tasySchedulingType: "Type",
          checkin: "Check in",
        },
        newSchedule: {
          success: "Successful registered scheduling",
          sched: "Schedule",
          notAvaiableModal: {
            button: "Ok",
            description:
              "The time chosen is no longer available, please select another time.",
          },
          next: "Next",
          back: "Back",
          finish: "Finish",
          no_type:
            "You do not have any appointments available, please contact the system administrators",
          steps: {
            patient: {
              title: "Select the patient",
              error: "The patient is mandatory",
            },
            insurance: {
              title: "Select the agreement",
              error: "The agreement is mandatory",
            },
            search: {
              title: "Search for an schedule",
              specialityError: "The specialty is mandatory",
              examError: "The exam is mandatoryo",
            },
            schedule: {
              title: "Select a date and time",
              error: "The time is mandatory",
            },
            confirm: "Confirm data",
          },
          insurance: "Health insurance",
          addInsurance: "Add Agreement",
          addFamilyGroup: "Add familiar",
          search: {
            from: "From",
            examGroup: "Exam",
            professional: "Professional (optional)",
            speciality: "Specialty",
            type: "Type of scheduling",
            period: "Period (optional)",
          },
          schedule: {
            freeDays: "Days with available dates",
          },
          confirm: {
            descriptionSite: "Professional",
            patient: "Patient",
            insurance: "Health insurance",
            schedule: "Scheduling",
            antecipate:
              "I would like to be notified if there is a free schedule before this date. In this case, if someone cancels a schedule before this date, we will notify you of this opportunity to fit in.",
            whatsapp_number: "WhatsApp Number",
            edit: "Edit",
            cancel: "Cancel",
            confirm: "Confirm",
            errors: {
              whatsapp_number: "The WhatsApp number is mandatory",
            },
          },
        },
      },
      login: {
        welcome: "Welcome back",
        username: "Username",
        cpf: "CPF",
        password: "Password",
        rememberme: "Remember me",
        forget_password: "Forgot password?",
        dont_account: "Don't have an account? Register",
        login: "Login",
        confirm_email: "Confirm email",
        errors: {
          login: "The login is mandatory",
          password: "Password must be at least 6 characters long",
        },
      },
      loginTemporary: {
        welcome: "Welcome",
        login: "Login",
        password: "Password",
        dont_account: "Don't have an account? Register",
        access_button: "Access",
        access_exam: "Access exams",
        errors: {
          login: "The login is mandatory",
          password: "The password is mandatory",
        },
      },
      not_found: {
        page_not_found: "Ooops! Page not found",
        server_not_responding: "Server not responding",
        try_again: "Try Again",
        back_to_begin: "Back to home",
        back_to_login: "Back to login",
        something_went_wrong: "Something went wrong",
      },
      documents: {
        title: "Terms and Policies",
        subtitle: "These are the documents you accepted",
        no_docs:
          "You have not accepted any documents yet. After accepting any document it will appear here",
      },
      home: {
        ticket: "Duplicate ticket",
        coParticipation: "Co-participation extract",
        schedule: "Schedule",
        paymentHistory: "Payment history",
        authorization: "Authorizations",
        dependent: "Dependent",
        irAndReimbursement: "IR",
        refunds: "Refund requests",
        slogan: "Make your everyday life easier",
        faq: "FAQ",
        health_program: "Health Programs",
        exams: "Exams result",
      },
      cards: {
        card: "Virtual Card",
        plan: "Plan",
        no_cards: "No card registered",
        view_card: "View card",
        show_shared_card: "Add shared card",
        share_card: "Share",
        name: "Name",
        number: "Number",
        birth_date: "Birth date",
        plan_name: "Product",
        accommodation: "Accommodation",
        coverage: "Coverage",
        accession: "Accession date",
        contract_name: "Contractor",
        card_validity: "Card validity",
        card_lack_description: "Lacks",
        card_lack_unlock_date: "Released from",
        card_lack_usage: "Card for personal and non-transferable use.",
        card_lack_presentation:
          "It must be presented together with an identification document.",
      },
      register: {
        register: "Register",
        document: "Document",
        has_register: "Already registered? Access your account",
        birth_date: "Birth Date",
        email: "Email",
        confirm_email: "Confirm email",
        password: "Password",
        confirm_password: "Confirm password",
        notification: "Registered successfully",
        errors: {
          document: "The document is mandatory",
          password: "The password is mandatory",
          email: "The email is mandatory",
          confirm_email: "The emails must match",
          confirm_password: "The passwords must match",
          birth_date: "The birth date is mandatory",
        },
        emailSendMessage:
          "We send an email to confirm the account creation.Please check the spam tab :)",
        backToLogin: "Go back to the login",
        individuals: {
          contacts: "Contacts",
          contact_type: "Contact type",
          add_contact: "Add contact",
          addresses: "Addresses",
          add_address: "Add address",
        },
      },
      irpf: {
        irpf: "IRPF Extract",
        no_data:
          "No IRPF information has been listed because you are not a payer of health insurance contracts. To get this report, please look for your contract with the health plan operator.",
        year: "Year",
        download_irpf: "Download IRPF",
        downloading: "Downloading",
        select_year: "Select year",
        plan: "Plan",
        no_file: {
          title: "File not available",
          description:
            "You do not have the {{year}} IRPF available for download.",
        },
      },
      extractCoParticipation: {
        extract_coparticipation: "Co-participation statement",
        select_date: "Select date",
        beneficiary: "Beneficiary",
        procedure: "Procedure",
        procedure_date: "Date",
        quantity: "Quantity",
        provider: "Provider",
        value: "Price",
        total: "Total",
        total_plan: "Plan total",
      },
      historyPayment: {
        history_payment: "Duplicate Ticket",
        title_number: "Title number",
        plan_name: "Plan name",
        plan: "Plan",
        reference_date: "Reference date",
        due_date: "Due date",
        payment_date: "Payment date",
        amount_fine: "Amount paid",
        amount_interest: "Interest amount",
        amount_penality: "Fine value",
        amount_monthly: "Amount monthly",
        download_ticket: "Download ticket",
        donwloads: "Download documents",
        detail: "Download detailing",
        select_year: "Select year",
        no_data:
          "No payment information has been listed because you are not a payer of health insurance contracts. To get this report, please look for your contract with the health plan operator.",
      },
      changePassword: {
        change_password: "Change password",
        actual_password: "Actual password",
        new_password: "New password",
        save: "Save",
        confirm_new_password: "Confirm new password",
        success_message: "Password changed successfully",
        errors: {
          actual_password: "The actual password is mandatory",
          new_password: "The new password is mandatory",
          confirm_new_password: "The confirm password is mandatory",
          passwords_match: "Passwords do not match",
        },
      },
      confirm_email: {
        confirm_email: "Confirm email",
        confirming: "Confirming",
        resent: "Email resent successfully",
        success: {
          title: "Confirmed email",
          description:
            "Your email has been successfully confirmed, go back to the login to access your account",
        },
        subtitle:
          'Enter the code received by email below and then click "Confirm email".',
        username: "Username",
        document: "CPF",
        code: "Code",
        error: {
          title: "There was an error",
          description: "An error occurred when confirming the email, try again",
          action: "Forward email",
          username: "The {{username}} is mandatory",
          code: "The code is mandatory",
        },
      },
      medicGuide: {
        title: "Results",
        show_result: "Your search was:",
        you: "You",
      },
      authorizations: {
        authorizations: "Authorizations",
        plan_name: "Plan",
        select_period: "Select a period",
        see_more: "See more",
        provider: "Provider",
        doctor: "Doctor",
        beneficiary: "Beneficiary",
      },
      authorization: {
        authorization: "Authorization",
        authorization_code: "Request number",
        issue_date: "Issue date",
        password_code: "Request password",
        password_expiration: "Password validity",
        situation: "Situation",
        provider: "Provider",
        doctor: "Doctor",
        procedures: "Procedures",
        history: "History",
        service_protocol: "Service protocol",
        doc_type: "Type",
        beneficiary: "Beneficiário",
      },
      noAccess: {
        message:
          "Oops! It looks like you don't have permission to access this page!",
        button: "Back to home",
      },
      whatsapp: {
        redirect: "Redirecting...",
      },
      refundsPage: {
        refunds: "Refund requests",
        new_refund: "New request",
        select_date: "Select the date",
        beneficiary: "Beneficiary",
        protocol_date: "Protocol date",
        situation: "Situation",
        due_date: "Due date",
        amount: "Amount",
        service_reason: "Reason for refund",
        refund: {
          refund: "New request",
          select_beneficiary: "Select a beneficiary",
          basic_data: "Basic data",
          others_data: "Banking information and contacts",
          name: "Name",
          type_guide: "Type of Service",
          service_date: "Service date",
          service_value: "Service value",
          service_location: "Location of service",
          service_reason: "Reason for refund",
          holder_name: "Holder name",
          holder_document: "CPF of the holder",
          holder_birth_date: "Birth date",
          bank_data: "Bank data",
          bank_data_no_found:
            "I didn't find the bank details in the list above and I want to enter them manually",
          bank: "Bank",
          bank_branch: "Bank branch",
          bank_account: "Bank account",
          bank_account_digit: "Digit",
          files: "Photos of the receipt for refund",
          file: "File",
          add_file: "Add file",
          contacts: "Contacts",
          phone_contact: "Phone",
          mail_contact: "Email",
          save: "Request",
          refund_requested: "Refund requested successfully",
          errors: {
            // Step 1
            type_guide: "The type of service is mandatory",
            service_date: "The service date is mandatory",
            service_value: "The service value is mandatory",
            service_location: "The location of service is mandatory",
            service_reason: "The reason for refund is mandatory",
            files: "At least one file is mandatory",
            file: "The file is mandatory",
            // Step 2
            bank_data: "The bank data is mandatory",
            holder_name: "The holder name is mandatory",
            holder_document: "The CPF of the holder is mandatory",
            bank: "The bank is mandatory",
            holder_birth_date: "The birth date is mandatory",
            bank_branch: "The bank branch is mandatory",
            bank_account: "The bank account is mandatory",
            bank_account_digit: "The digit is mandatory",
            phone_contact: "The phone is mandatory",
            mail_contact: "The email is mandatory",
            mail_contact_val: "Enter a valid email",
          },
        },
      },
      scheduleTeleconsultation: {
        schedule_teleconsultation: "Schedule a teleconsultation",
        choose_service: "What service do you want?",
        virtual_emergency_care: "Virtual Emergency Care",
        service: "Video consultation with a doctor, in order of activation.",
        service_call:
          "Telephone consultation with a doctor, in order of activation.",
      },
      emergencyCareScheduling: {
        appointment_scheduling: "Scheduling appointment",
        choose_patient: "Who do you want to schedule an appointment for?",
        dependents: "Dependent(s)",
      },
      virtualEmergencyCare: {
        virtual_emergency_care: "Virtual Emergency Care",
        message:
          'Hello, this is our Virtual Emergency Care service. Here you will have access to a doctor wherever you are and whenever you need one. Enter your symptoms, click on "Request Service" and wait until you are attended to.',
      },
      faq: {
        faq: "FAQ",
        search: "Search",
        search_placeholder: "Search by a question or answer",
        no_data: "No result found",
        no_questions_registered: "No question registered",
      },
      allNews: {
        title: "News",
        no_news: "No news to display",
        search_label: "Search",
        search_placeholder: "Search by news or tags",
      },
      news: {
        reading_time: "Estimated reading time: {{readingTime}} minutes",
        publication_date: "Publication date:",
        complementaryMaterial: "Complementary material",
        no_video: "The video was not found",
      },
      notifications: {
        title: "Notifications",
        mark_all_as_read: "Mark all as read",
      },
      notification: {
        title: "Notification",
        created: "Publication date",
        read: "Reading date",
      },
      health_programs: {
        title: "Health programs",
        search: "Search for a health program",
        not_participate: "You still don't participate in any health program",
        no_found: "No program found",
        myPrograms: "My programs",
        otherPrograms: "Other programs",
        seeMore: "View more",
        exit: "Exit",
        requestToEnter: {
          title: "It was not possible to view",
          description:
            "You are not part of this program.Want to request to enter?",
          button1: "Cancel",
          button2: "Request",
        },
        refused: {
          title: "Refused request",
          button1: "Cancel",
          button2: "Reset",
        },
        hasRequested: {
          title: "Request in progress",
          description: "Wait for the response of those responsible",
          button1: "Ok",
        },
        exitProgram: {
          title: "Get out of the program?",
          description:
            "Are you sure you want to leave the program?You will need to request permission to enter again.",
          button1: "Out of the program",
          button2: "Cancel",
        },
        dateInfo: "{{date}} - Reading time {{readingTime}} min",
      },
      exams: {
        exams: "Exams result",
        procedure: "Procedure",
        type: "Procedure Type",
        doctor: "Doctor",
        unit: "Unit",
        date: "Date",
        image: "Images",
        report: "Report",
        search: "Search",
        date_range_info: "The maximum limit for consultation is 1 year",
        patient: "Patient",
      },
      myRegistration: {
        my_registration: "My Registration",
        my_registration_menu: "My registration",
        name: "Name",
        document: "CPF",
        birth_date: "Birth Date",
        contacts: "Contacts",
        contact_type: "Contact type",
        add_contact: "Add contact",
        save: "Save",
        cancel: "Cancel",
        success: "Registration updated successfully",
      },
    },
    components: {
      menu: {
        home: "Home",
        whats: "Whatsapp",
        faq: "FAQ",
        schedule: "Schedules",
        virtualEmergencyCare: "Telemedicine",
        cards: "Virtual card",
        ticket: "Duplicate ticket",
        co_participation: "Co-participation extract",
        irpf: "IRPF",
        authorizations: "Authorizations",
        plan: "Insurance plan",
        config: "Configs",
        change_password: "Change password",
        change_language: "Change language",
        logout: "Logout",
        settings: "Settings",
        whatsapp: {
          title: "Under development",
          description:
            "Something amazing is in the making. It will be ready soon!",
        },
      },
      backButton: "Back",
      private: {
        permission: "Ooops! You don't have permission!",
      },
      themeSwitch: {
        change_theme: "Change Theme",
      },
      languageSwitch: {
        change_language: "Change language",
      },
      recents: {
        status: {
          analyze: "Under review",
          expiresToday: "Expires today",
        },
      },
      navigationCardsPage: {
        title: "Virtual card",
        action: "View your virtual cards or cards shared with you",
      },
      notifications: "Notifications",
      no_data: "No data to show",
      establishments: {
        select: "Select a establishment",
        change: "Change establishment",
      },
      beneficiaryCard: {
        share: "Who am I sharing with:",
      },
      medicCardShare: {
        share_with:
          "Enter the document with which you want to share the virtual card {{cardCode}} of {{individualName}}",
        responsibility_message:
          "It is the responsibility of the HOLDER of the health plan to share and disseminate the personal information contained in this virtual card. THE HOLDER consents to the sharing under his responsibility and is aware of LAW No. 13,709 which provides for the protection of personal data.",
        read: "I have read and agree to the above terms",
        document: "Document",
        name: "Person name",
        cancel: "Cancel",
        share: "Share",
        errors: {
          document: "The document is mandatory",
          name: "The name is mandatory",
          terms: "You must agree to the terms to share",
        },
        success: "Successfully shared virtual card",
        deleted: "You stopped sharing with {{name}}",
      },
      medicCardShared: {
        message:
          "Please enter your DOCUMENT and the code to display the card shared with you.",
        see_card: "Add card",
        document: "Document",
        code: "Código",
        errors: {
          document: "The document is mandatory",
          code: "The code is mandatory",
          card_added: "Virtual card already added",
        },
      },
      changeLanguageForm: {
        title: "Change language",
        language: "Language",
        button: "Change",
      },
      autocomplete: {
        noOptions: "No options",
        hint: "Type at least 3 characters to search",
      },
      medicGuide: {
        medic_guide: "Medical Guide",
        subtitle: "Search by doctor, specialty, clinic...",
        search: "What are you looking for?",
      },
      downloadTicket: {
        ticket_value: "Bill value",
        ticket_payment_date: "Due date",
        payment_code: "Payment code",
        copy_code: "Copy code",
        download_ticket: "Download ticket",
        downloading: "Downloading",
        code_copied: "Copied code",
      },
      documentAcceptance: {
        document_acceptance: "Documents",
        document_message:
          "By accepting you agree and accept the documents listed below",
        document_accept:
          "You must accept the documents to continue using the site",
        document_accepted: "Documents accepted successfully",
        need_accept: "You must accept the terms to continue",
        accept: "Accept",
        image_alt: "Paper",
        language: "Language",
        content: "Content",
      },
      document: {
        acceptIn: "Accepted in {{date}}",
      },
      autoCompleteVoice: {
        permission: "You need to allow microphone",
        support: "Browser doesn't support speech recognition",
        no_options: "No options",
        specialty: "Specialty",
        practice_area: "Practice area",
        city: "City",
        health_plan: "Plan",
        professional: "Name",
        provider_type: "Provider type",
      },
      avatarDropdown: {
        tooltip_user: "Account settings",
        tooltip_visit: "Login",
      },
      forgetPasswordForm: {
        title: "Esqueceu a senha?",
        subtitle:
          "Enter your CPF below and we'll send you a code to your email to reset your password.",
        button: "Send",
        login: "CPF",
        login_required: "Email is required!",
        notification_email_sent: "Email sent successfully!",
        back_login: "Back to login",
        notification_email_confirmed:
          "Email confirmed successfully, please enter your access details again.",
      },
      resetPasswordForm: {
        title: "New password",
        subtitle:
          "Enter the code sent to your email. If you can't find it, check your spam area.",
        password: "Password",
        confirm_password: "Confirm password",
        confirmation_code: "Confirmation code",
        new_password_required: "Password is required.",
        confirm_new_password_required: "Confirm password is required.",
        passwords_match: "Passwords do not match.",
        confirmation_code_required: "The confirmation code is mandatory",
        notification_password_success: "Password changed successfully!",
        counter: "Resend email available in {{counter}} second(s)",
      },
      resendEmail: {
        title: "It looks like you haven't confirmed your email yet.",
        description: "Click the button below to resend the confirmation email.",
        button: "Resend email",
        success: "Email sent successfully!",
      },
      downloadPageButton: {
        error: "Download failed",
      },
      procedures: {
        code: "Code",
        authorized_quantity: "Authorized Qty",
        requested_quantity: "Requested Qty",
        pending_execution_quantity: "Pending Execution Qty",
        executed_quantity: "Executed Qty",
      },
      stepperActions: {
        back: "Back",
        next: "Next",
      },
      stepperHeader: {
        errors: {
          fields: "Mandatory field blank or with error",
        },
      },
      inputFileStatus: {
        tooltip_view_file: "View file",
        tooltip_try_again: "Try again",
      },
      inputFileValidations: {
        invalid_type: "Unsupported file type",
        invalid_size: "Unsupported file size. (Max {{maxFileSize}} MB)",
      },
      refundsModal: {
        title: "Select a beneficiary",
        select: "Select",
        beneficiary: "Beneficiary",
        errors: {
          beneficiary: "The beneficiary is mandatory",
        },
      },
      schedulingBeneficiary: {
        birth_date: "Birth date",
        gender: "Gender",
        mail: "Email",
        phone: "Telephone",
      },
      emergencyCareSymptoms: {
        symptoms: "What are your symptoms?",
        request_service: "Request service",
        requesting: "Requsting",
        beneficiary_symptoms: "Fever, headache...",
        cancel: "Cancel",
      },
      medicGuideFilter: {
        detailed_search: "Detailed search",
        legends: "Subtitles",
        plan: "Plan",
        province: "State",
        city: "City",
        neighborhood: "Neighborhood",
        specialty: "Specialty",
        character_service: "Character of service",
        occupation_area: "Occupation area",
        provider_type: "Provider type",
        doctor: "Doctor",
        search: "Search",
        name: "Name",
        emergency: "Urgent and emergency care only",
        clean: "Clear filters",
        no_medic_found: "No doctor found!",
        specialties: "Specialties",
        practice_area: "Occupation area",
        phone: "Phone",
        cellphone: "Cellphone",
        whatsapp: "Whatsapp",
        website: "Site",
        mail: "Email",
        filters_added: "Filters added successfully.",
        filters_removed: "Filters removed successfully.",
        view_more: "See more",
        view_less: "See less",
      },
      medicGuideActions: {
        list: "List",
        map: "Map",
      },
      queueModal: {
        go_queue: "Go to queue",
        copy_link: "Copy link",
        link_copied: "Link copied successfully",
        image_alt:
          "Cell phone with a stethoscope, symbol of the virtual emergency service",
        title: "It won't be long before you're attended to!",
        subtitle:
          'You are already in the queue, just click on the "Go to queue" button and wait to be served.',
        close: "Close",
      },
      lastCalls: {
        last_calls: "Last calls",
        return: "Resume",
        return_tooltip: "Return to service",
        use_symptoms: "Use symptoms",
        symptoms: "Simptoms",
      },
      displayPlan: {
        plan: "Plan",
      },
      refundSelectBeneficiary: {
        dependents: "Dependent(s)",
      },
      autoCompleteBankData: {
        short_name: "Bank",
        bank_branch: "Bank branch",
        bank_account: "Account",
        holder_account: "Holder",
      },
      legends: {
        national: "National quality standard",
        international: "International quality standard",
        events_comunication: "Reporting adverse events",
        monitored_quality: "Quality monitored",
        residence_professional: "Professional with residence",
        specialty_professional: "Specialized professional",
        specialist_title: "Specialist title",
      },
      avaliationsButtons: {
        title: "Was this article helpful?",
      },
      avaliationInput: {
        placeholder:
          "We appreciate your feedback, add a comment here to help improve the article.",
        button: "Submit comment",
        sending: "Sending",
        back: "Back",
      },
      rated: {
        message: "We appreciate your feedback!",
      },
      registeredModal: {
        title: "Registration completed successfully!",
        description:
          "Please click the button below and then follow the steps to confirm your email.",
        button: "Ok",
      },
      emailResent: {
        title: "Email resent successfully!",
        description:
          "We resend the code to the registered email, check your email, copy the confirmation code, click the button below and follow the steps to confirm your email.",
        button: "Ok",
      },
      registerError: {
        button: "Ok",
      },
      homeBannersImage: {
        tooltip: "Do not display this banner anymore",
      },
      closeAllBanners: {
        button: "Do not display these banners anymore",
      },
      homeBanners: {
        notification: "The Banner will no longer be displayed",
        all_banners_notification: "The Banners will no longer be displayed",
      },
      newsList: {
        news: "News",
        view_more: "View more",
      },
      recentNews: {
        no_news: "No recent news to display",
      },
      newsCard: {
        reading_time: "Estimated reading time",
      },
      notificationsButton: {
        tooltip: "Notifications",
      },
      noNotifications: {
        recent_message: "No recent notifications",
        message: "No notifications",
      },
      unreadNotificationItem: {
        mark_as_read: "Mark as read",
      },
      unreadNotifications: {
        notifications: "Notifications",
      },
      unreadNotificationsActions: {
        view_all: "See all",
        mark_all_as_read: "Mark all as read",
      },
      notificationCard: {
        new: "New",
        mark_as_read: "Mark as read",
        created: "Publication date",
        read: "Reading date",
      },
      notificationNotFound: {
        message: "Notification not found",
      },
      noRowsMessage: {
        no_permission: "You do not have permission to access this information",
        no_data: "No data to display",
      },
      examsDownload: {
        open_report: "Open report",
        report: "Report",
      },
      examsPdf: {
        no_data: "File not found or empty",
      },
      examsRedirectFile: {
        download_image: "Download image(s)",
      },
      pagesActions: {
        previous_page: "Previous page",
        next_page: "Next page",
        zoom_out: "Zoom out",
        zoom_in: "Zoom in",
        pages: "Page {{pageNumber}} of {{numPages}}",
        download_pdf: "Download PDF",
        print: "Print",
      },
      accessTemporaryMessage: {
        access_temporary: "Temporary access",
        register: "Register to have access to all the resources of our portal",
        login: "Login to have access to all the resources of our portal",
        register_btn: "Register",
        login_btn: "Login",
      },
      columnsSelect: {
        visible_columns: "Visible columns",
        subtitle: "Select the columns you want to display",
        select_all: "Select all",
      },
      procedureType: {
        all: "All",
        laboratorial: "Laboratory",
        no_laboratorial: "Non-Laboratory",
      },
      dateRange: {
        start_date: "Start date",
        end_date: "End date",
      },
      renderPDF: {
        error: "Error loading file",
      },
      examsRedirectModal: {
        title: "Image(s)",
      },
      fileItem: {
        download_file: "Download",
        preview: "View",
        image: "Image",
        open_new_tab: "Open file in new tab",
      },
      accessPortalBtn: {
        button: "Access our portal",
      },
      formSucceeded: {
        title: "Thank you for your participation!",
        subtitle: "Your response to our form is very valuable to us.",
        button: "Go to portal",
        view_answers: "View answers",
      },
      formNotFound: {
        message: "Form not found",
        subtitle: "Please check the URL and try again",
        button: "Go to portal",
      },
      formStepsActions: {
        back: "Back",
        next: "Next",
        end: "Finish",
      },
      step: {
        answer_success: "Answer sent successfully",
        update_answer_success: "Answer updated successfully",
        errors: {
          alternative: "The alternative is mandatory",
        },
      },
      whatsappNumber: {
        title: "WhatsApp number",
        whatsapp_number: "WhatsApp number",
        success: "WhatsApp number updated successfully",
        cancel: "Cancel",
        save: "Save",
        errors: {
          whatsapp_number: "The WhatsApp number is mandatory",
        },
      },
      reports: {
        reports: "Reports",
        view_reports: "View reports",
        back: "Back",
        file: "File",
        view: "View",
        download: "Download",
      },
      checkInButton: {
        check_in: "Check in",
        checked_in: "Check in already completed, please go to the location:",
        location: "To check in, you must allow access to the location",
        appointment_canceled: "Appointment Canceled, check-in is not possible",
        is_late: "The Check in deadline has passed",
        is_in_time: "Check in can only be done {{time}} minutes before",
        is_near: "You need to be within {{meters}}m to Check In",
      },
      checkedIn: {
        message: "Check-in successful, please go to the location:",
      },
      reportsModal: {
        loading: "Loading...",
        not_found: "File not found",
        file: "File",
        actions: {
          open: "Open",
          view: "View",
          download: "Download",
        },
      },
      nonTasyRegister: {
        finish_register: "Complete Registration",
        name: "Name",
        whatsapp_number: "WhatsApp Number",
        success: "Registration completed successfully",
        helpers: {
          frontDoc: {
            title: "Front Photo",
            subtitle:
              "Take a photo of the front of your ID or driver's license, the side where your photo appears.",
          },
          backDoc: {
            title: "Back Photo",
            subtitle:
              "Take a photo of the back of your ID or driver's license, the side that only contains information.",
          },
          selfie: {
            title: "Selfie",
            subtitle:
              "Take a photo of your face so we can identify you. Look for places with good lighting and avoid wearing glasses and/or a hat.",
          },
          selfieDoc: {
            title: "Selfie with document",
            subtitle:
              "Take a photo of your face holding your photo ID next to your face. Look for places with good lighting and avoid wearing glasses and/or a hat.",
          },
        },
        photosSection: {
          title: "Send your documents",
          front_doc: "Photo Document Front",
          back_doc: "Photo Document Back",
          selfie: "Selfie",
          selfie_doc: "Selfie with Document",
          helper:
            "* Sending documents is important so that our team can validate and confirm your registration. Sending documents is secure and follows LGPD guidelines.",
        },
        actions: {
          back: "Back",
          register: "Register",
        },
        errors: {
          name: "The name is mandatory",
          whatsapp_number: "The WhatsApp number is mandatory",
          photos: "The photos are mandatory",
        },
      },
    },
    logout: "Logout",
    whatsapp_number: "WhatsApp number",
    change_password: "Change password",
    change_language: "Change language",
    access_denied: "You need to be login, to access this page",
  },
};

export default en_US;
