import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ISelectType } from "../../../../../components/UI/Inputs/AutoCompleteText";
import RadioGroupInput from "../../../../../components/UI/Inputs/RadioGroupInput";
import useFetch from "../../../../../hooks/useFetch";
import { Extension } from "../../../../../models/intalled-extensions";
import ExtensionService from "../../../../../services/extension.service";
import { ScheduleAppointmentService } from "../../../appointment.service";
import { TAppointment } from "../../schema";

const extensionService = new ExtensionService();

type TScheduleTypeCode = "2" | "3";

const Type = () => {
  const [types, setTypes] = useState<ISelectType[]>([]);

  const [showConsultation, setShowConsultation] = useState(false);

  const [showExams, setShowExams] = useState(false);

  const [extensionsLoaded, setExtensionsLoaded] = useState(false);

  const { t } = useTranslation();

  const { setValue } = useFormContext<TAppointment>();

  const { sendRequest, loading: loadingTypes } = useFetch(
    ScheduleAppointmentService.getTypes
  );

  const { sendRequest: extensionConfig, loading: loadingExtensionConfigs } =
    useFetch(extensionService.getExtensionConfig);

  const fetch = useCallback(async () => {
    const { data, success } = await sendRequest(null);

    if (data && success) {
      setTypes(
        data.filter((d) => {
          if (d.code === "3") return showConsultation;
          if (d.code === "2") return showExams;
          return true;
        })
      );
    }
  }, [sendRequest, showConsultation, showExams]);

  const fetchExtensionConfig = useCallback(
    async (Identifier: string) => {
      const { data, success } = await extensionConfig({
        ExtensionCode: Extension.SCHEDULE,
        Identifier,
      });
      if (data && success) {
        setExtensionsLoaded(true);
        switch (Identifier) {
          case "ShowConsultationToSchedule":
            setShowConsultation(data.length > 0);
            break;
          case "ShowExamsToSchedule":
            setShowExams(data.length > 0);
            break;
        }
      }
    },
    [extensionConfig]
  );

  useEffect(() => {
    if (types.length === 1) {
      setValue("search.type", String(types[0].code) as TScheduleTypeCode);
    }
  }, [setValue, types]);

  useEffect(() => {
    if (!extensionsLoaded) return;
    fetch();
  }, [fetch, extensionsLoaded]);

  useEffect(() => {
    fetchExtensionConfig("ShowExamsToSchedule");
    fetchExtensionConfig("ShowConsultationToSchedule");
  }, [fetchExtensionConfig]);

  const loading = loadingTypes || loadingExtensionConfigs;

  if (types.length === 0 && extensionsLoaded && !loading) {
    return (
      <Typography color="error" variant="body2" sx={{ mb: 2 }}>
        {t("page.schedules.newSchedule.no_type")}
      </Typography>
    );
  }

  return (
    <RadioGroupInput
      name="search.type"
      groupLabel={t("page.schedules.newSchedule.search.type")}
      disableBorder
      disabled={types.length === 1}
      radios={types.map((i) => ({
        label: i.name,
        value: i.code!,
      }))}
    />
  );
};

export default memo(Type);
