import { Form, useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Divider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UserData } from "../../components/MyRegistration/UserData/UserData";
import PagesContainer from "../../components/UI/PagesContainer";
import {
  getIndividualData,
  updateIndividualData,
} from "../../services/individual.service";
import { defaultScrollTheme } from "../../utils/utils";
import { Action } from "./Action/Action";
import IndividualAdresses from "./Addresses/IndividualAddresses";
import IndividualContacts from "./Contacts/IndividualContacts";
import { Loading } from "./Loading/Loading";
import {
  myRegistrationSchema,
  TMyRegistrationForm,
} from "./myRegistrationSchema";

const translationPath = "page.myRegistration.";

const MyRegistrationPage = () => {
  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const [name, setName] = useState<string | null>(null);

  const [document, setDocument] = useState<string | null>(null);

  const [birthDate, setBirthDate] = useState<string | null>(null);

  const { sendRequest: get, loading: gLoading } = useFetch(getIndividualData);

  const { sendRequest: update, loading: uLoading } =
    useFetch(updateIndividualData);

  const methods = useForm<TMyRegistrationForm>({
    resolver: zodResolver(myRegistrationSchema),
  });

  const { handleSubmit } = methods;

  const fetchIndividualData = useCallback(async () => {
    const { data, success } = await get(null);
    if (data && success) {
      setName(data.name);
      setDocument(data.document);
      setBirthDate(data.birthDate);
      methods.reset({
        ...data,
        addressess: data.addressess.map((address) => ({
          id: address.id,
          addressType: address.addressType,
          otherType: address.otherType,
          otherNeighborhood: address.neighborhood ? false : true,
          zipCode:
            address.zipCode.substring(0, 5) +
            "-" +
            address.zipCode.substring(5),
          city: address.city,
          provincy: address.city.provincy,
          addressDescription: address.addressDescription,
          neighborhoodName: address.neighborhoodName
            ? address.neighborhoodName
            : null,
          neighborhood: address.neighborhood ? address.neighborhood : null,
          number: address.number || undefined,
          complement: address.complement || undefined,
        })),
      });
    }
  }, [get, methods]);

  useEffect(() => {
    fetchIndividualData();
  }, [fetchIndividualData]);

  const onSubmitHandler = useCallback(
    async (values: TMyRegistrationForm) => {
      const { data, success } = await update({
        contacts: values.contacts.map((contact) => ({
          contactTypeId: contact.contactType.id,
          value: contact.value,
          otherType: contact.otherType || "",
        })),
        addressess: values.addressess.map((address) => ({
          id: address.id,
          cityId: address.city?.id || "",
          neighborhoodId: !address.otherNeighborhood
            ? address.neighborhood?.id
            : undefined,
          addressTypeId: address.addressType.id,
          otherType: address.otherType || null,
          zipCode: address.zipCode.replaceAll("-", ""),
          addressDescription: address.addressDescription,
          neighborhoodName: address.otherNeighborhood
            ? address.neighborhoodName
            : undefined,
          number: address.number || null,
          complement: address.complement,
        })),
      });
      if (data && success) {
        setMessage({
          type: "success",
          message: t(translationPath + "success"),
        });
        fetchIndividualData();
      }
    },
    [update, setMessage, t, fetchIndividualData]
  );

  return (
    <PagesContainer title={t(`${translationPath}my_registration`)}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {gLoading && <Loading />}
          {!gLoading && (
            <>
              <UserData name={name} birthDate={birthDate} document={document} />
              <Form {...methods}>
                <Box
                  component="form"
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmitHandler)}
                  sx={(theme) => ({
                    mt: 2,
                    pb: 10,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    ...defaultScrollTheme(theme),
                  })}
                >
                  <IndividualContacts />
                  <Divider sx={{ my: 2 }} />
                  <IndividualAdresses />
                  <Action loading={uLoading} />
                </Box>
              </Form>
            </>
          )}
        </Box>
      </Box>
    </PagesContainer>
  );
};

export default MyRegistrationPage;
