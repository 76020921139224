import { FormLabel, Stack } from "@mui/material";

import { useCallback, useMemo } from "react";
import { genericMemo } from "../../../utils/utils";
import IconButton from "./IconButton";
import { IItem, ISelectIconProps } from "./model";

const SelectIcon = <T extends IItem>({
  options,
  label,
  addIcon,
  ...props
}: ISelectIconProps<T>) => {
  const memoizedProps = useMemo(() => props, [props]);

  const { onChange, value, multi } = memoizedProps;

  const onClickHandler = useCallback(
    (data: T) => {
      if (multi) {
        if (!value) {
          onChange([data]);
        } else {
          const index = value.findIndex((d) => d.id === data.id);

          if (index === -1) {
            value.push(data);
          } else {
            value.splice(index, 1);
          }

          onChange(value);
        }
      } else if (multi === false) {
        if (value?.id === data.id) {
          onChange(null);
        } else {
          onChange(data);
        }
      }
    },
    [onChange, value, multi]
  );

  const isSelected = useCallback(
    (v: T): boolean => {
      if (multi) {
        return !!value?.find((pV) => pV.id === v.id);
      } else if (multi === false) {
        return value?.id === v.id;
      }

      return false;
    },
    [value, multi]
  );

  return (
    <Stack gap={1}>
      {label && <FormLabel>{label}</FormLabel>}
      <Stack flexDirection={"row"} gap={1}>
        {options.map((o) => (
          <IconButton
            item={o}
            onClick={onClickHandler}
            selected={isSelected(o)}
            key={o.id}
          />
        ))}
        {addIcon && addIcon}
      </Stack>
    </Stack>
  );
};

export default genericMemo(SelectIcon);
