import { Button, useFetch } from "@4uhub/lib4uhub";
import WhereToVoteRoundedIcon from "@mui/icons-material/WhereToVoteRounded";
import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDiffDates } from "../../../../hooks/useDiffDates";
import { useDistance } from "../../../../hooks/useDistance";
import {
  checkInConsultation,
  checkInExam,
} from "../../../../services/checkIn.service";
import { ICheckInButtonProps } from "./models";

const translationPath = "components.checkInButton.";

export const CheckInButton = memo(
  ({
    checkInRule,
    scheduleDate,
    encounterIdentifier,
    calendarStatusCode,
    roomName,
    scheduleTypeCode,
    identifier,
  }: ICheckInButtonProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const theme = useTheme();
    const statusCancel = calendarStatusCode === "C" ? true : false;

    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const { distanceBetweenCoordinates } = useDistance();

    const { calculateDiffDates } = useDiffDates();

    const [position, setPosition] = useState<GeolocationPosition | null>(null);

    const [positionError, setPositionError] =
      useState<GeolocationPositionError | null>(null);

    const [isNear, setIsNear] = useState(false);

    const { sendRequest, loading } = useFetch(
      scheduleTypeCode === "3" ? checkInConsultation : checkInExam
    );

    const successCallback = useCallback((position: GeolocationPosition) => {
      setPosition(position);
    }, []);

    const errorCallback = useCallback((error: GeolocationPositionError) => {
      setPositionError(error);
    }, []);

    const fetchLocation = useCallback(() => {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }, [successCallback, errorCallback]);

    const checkInHandler = useCallback(
      async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        if (!identifier) return;
        const { data, success } = await sendRequest({
          identifier: Number(identifier),
        });
        if (data && success) {
          navigate(
            `/schedule/mySchedules/${scheduleTypeCode}/${identifier}/checkedIn`
          );
        }
      },
      [navigate, sendRequest, scheduleTypeCode, identifier]
    );

    useEffect(() => {
      fetchLocation();
    }, [fetchLocation]);

    const distance = useMemo(
      () =>
        position && checkInRule
          ? distanceBetweenCoordinates(
              Number(checkInRule.latitude),
              Number(checkInRule.longitude),
              position.coords.latitude,
              position.coords.longitude,
              "meters"
            )
          : null,
      [checkInRule, position, distanceBetweenCoordinates]
    );

    const minutesToSchedule = calculateDiffDates(scheduleDate, "minutes");

    const isInTime =
      minutesToSchedule && checkInRule && minutesToSchedule <= checkInRule.time;

    const isLate = new Date() > new Date(scheduleDate);

    useEffect(() => {
      if (distance === null) return;

      if (checkInRule && distance <= checkInRule.metersApproximation) {
        setIsNear(true);
        return;
      }
      setIsNear(false);
    }, [distance, checkInRule]);

    let content = <Typography variant="subtitle2"> - </Typography>;

    if (!encounterIdentifier) {
      content = (
        <Button
          disabled={
            !!positionError ||
            !position ||
            !isNear ||
            !isInTime ||
            isLate ||
            statusCancel
          }
          loading={loading}
          startIcon={<WhereToVoteRoundedIcon />}
          onClick={checkInHandler}
          sx={(t) => ({
            backgroundColor:
              t.palette.mode === "light" ? "white" : t.palette.grey[800],
            color: t.palette.primary.main,
            "&:hover": {
              backgroundColor: t.palette.primary.dark,
              color: "white",
            },
          })}
        >
          {t(`${translationPath}check_in`)}
        </Button>
      );
    }
    if (encounterIdentifier) {
      content = (
        <>
          <Typography variant="subtitle2">
            {t(`${translationPath}checked_in`)}
          </Typography>
          <Typography fontWeight="bold">{roomName ?? "-"}</Typography>
        </>
      );
    }

    if (statusCancel) {
      content = (
        <Typography variant="subtitle2">
          {t(`${translationPath}appointment_canceled`)}
        </Typography>
      );
    }
    return (
      <Tooltip
        title={
          statusCancel
            ? t(`${translationPath}appointment_canceled`)
            : !!positionError || !position
            ? t(`${translationPath}location`)
            : isLate
            ? t(`${translationPath}is_late`)
            : !isInTime && checkInRule
            ? t(`${translationPath}is_in_time`, { time: checkInRule.time })
            : !isNear && checkInRule
            ? t(`${translationPath}is_near`, {
                meters: checkInRule.metersApproximation,
              })
            : ""
        }
        disableHoverListener={
          statusCancel ||
          encounterIdentifier ||
          (position && isNear && isInTime && !isLate)
            ? true
            : false
        }
        disableFocusListener={
          statusCancel ||
          encounterIdentifier ||
          (position && isNear && isInTime && !isLate)
            ? true
            : false
        }
      >
        <Box
          sx={{
            width: matches ? "100%" : "fit-content",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {content}
        </Box>
      </Tooltip>
    );
  }
);
