import GlobalAxios from "../axios/axios-config";
import { IAlertTypeUser } from "../models/alert-type";

const ALERT_TYPE_USER_ROUTE =
  process.env.REACT_APP_APP + "/api/v1/AlertTypeUser/";

export const getAlertTypeUser = () => {
  return GlobalAxios.get<IAlertTypeUser[]>(
    ALERT_TYPE_USER_ROUTE + "GetAlertTypeUser"
  );
};
