import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EventIcon from "@mui/icons-material/Event";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import ExamsDownload from "../../components/Exams/ExamsDownload/ExamsDownload";
import ExamsRedirectModal from "../../components/Exams/ExamsRedirectFile/ExamsRedirectModal";
import { Reports } from "../../components/Exams/Reports/Reports";
import ModalComponent from "../../components/UI/Modal";
import Row from "../../components/UI/Row";
import { IExams } from "../../models/exams";

interface IExamItemProps {
  data: IExams;
  individualId?: string;
}
const translationPath = "page.exams.";

const ExamItem: React.FC<IExamItemProps> = ({ data, individualId }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <ModalComponent onClose={() => setOpen(false)} open={open}>
        <Stack gap={1}>
          <Row
            label={t(translationPath + "procedure")}
            value={data.medicalCertificateName || "-"}
          />

          <Row
            label={t(translationPath + "doctor")}
            value={data.physicianName || "-"}
          />

          <Row
            label={t(translationPath + "unit")}
            value={data.establishmentName || "-"}
          />

          <Row
            label={t(translationPath + "date")}
            value={dayjs(data.medicalCertificateDate).format("L")}
          />

          <Row
            label={t(translationPath + "report")}
            value={
              data.formatTypeCode === "3" ? (
                <Reports
                  identifier={data.id}
                  id={data.id.toString()}
                  examType={data.type}
                  date={data.medicalCertificateDate}
                  individualId={individualId}
                />
              ) : !data.medicalCertificateContent ||
                data.medicalCertificateContent.length === 0 ? (
                "-"
              ) : (
                <ExamsDownload
                  id={data.id.toString()}
                  examType={data.type}
                  date={data.medicalCertificateDate}
                />
              )
            }
          />

          {data.sequencialImageControls.length > 0 && (
            <ExamsRedirectModal
              sequencialControl={data.sequencialControl}
              sequencialImageControls={data.sequencialImageControls}
            />
          )}
        </Stack>
      </ModalComponent>
      <Stack
        sx={(t) => ({
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1,
          backgroundColor: t.palette.divider,
          borderRadius: t.shape.borderRadius / 3,
        })}
        onClick={() => setOpen(true)}
      >
        <Stack>
          <Typography sx={{ fontWeight: "bold" }}>
            {data.medicalCertificateName}
          </Typography>
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <EventIcon />
            <Typography>
              {dayjs(data.medicalCertificateDate).format("L")}
            </Typography>
          </Stack>
        </Stack>
        <ChevronRightIcon />
      </Stack>
    </>
  );
};

export default memo(ExamItem);
